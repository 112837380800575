import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useRef, useLayoutEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';

export default function LoanEdit() {
  const navigate = useNavigate();
  const location = useLocation();

  const [leaveNumber, setleaveNumber] = useState();
  const [leaveDate, setleaveDate] = useState(dayjs().startOf('day'));
  const [employeeName, setemployeeName] = useState(null);
  const [EmpList, setEmpList] = useState([]);

  const [ReasonNameList, setReasonNameList] = useState([]);

  const [Leave, setLeave] = useState();
  const [FromDate, setFromDate] = useState(dayjs().startOf('day'));
  const [ToDate, setToDate] = useState(dayjs().startOf('day'));
  const [Reason, setReason] = useState(null);
  const [Remark, setRemark] = useState();
  const [LeaveType, setLeaveType] = useState([]);
  const [LoanType, setLoanType] = useState(null);
  const [amount, setamount] = useState(0);
  const [Installment, setInstallment] = useState(0);
  const [Interest, setInterest] = useState(0);
  const [guarantors, setguarantors] = useState(null);
  const [bank, setbank] = useState();
  const [cheque, setcheque] = useState();

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [actionMessage, setActionMessage] = useState('');

  const [EditID, setEditID] = useState();
  const [data, setdata] = useState();

  const lastContentRef = useRef(null);
  useLayoutEffect(() => {
    if (lastContentRef.current) {
      lastContentRef.current.style.marginBottom = '70px';
    }
  }, []);

  const fetchLeaveData = async (LeaveID) => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/loan_reqs/LoanReqListGET`,
        {
          params: {
            LeaveID: LeaveID,
            masterid,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString)
          },
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      return response?.data?.lastEntryNo[0]
    } catch (error) {
      console.error('Error fetching data:', error);
      return null
    }
  };


  const handleSave = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');

      if (!guarantors) {
        setActionMessage('A guarantor is required. Please select one to continue.')
        setErrorModalOpen(true)
        return;
      }
      
      const data = {
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid,
        loanNumber: leaveNumber,
        LoanDate: leaveDate,
        employeeName: employeeName?._id,
        guarantors: guarantors?._id,
        LoanType: LoanType,
        amount,
        Installment,
        Interest,
        FromDate,
        ToDate,
        bank,
        cheque,
        SelectedDays: getSelectedDays(),
        Reason: Reason?._id,
        Remark,
        EditID
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/loan_reqs/LoanReqEdit`, data, {
        headers: {
          authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.data.status) {
            setActionMessage(response.data.message)
            setSuccessModalOpen(true);
            setTimeout(() => {
              navigate('/transaction/LoanReqList');
            }, 1000);
          } else {
            setActionMessage(response.data.message)
            setErrorModalOpen(true)
          }
        })
        .catch(error => {
          console.log(error)
          setActionMessage("Ooops an error occured.")
          setErrorModalOpen(true)
        });
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const EmpListGET = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');

      const data = {
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid
      }
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/EmployeeNamesGET`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      setEmpList(response.data.lastEntryNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const ReasonsGET = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');

      const data = {
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid
      }
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/leave_reqsRouter/ReasonsGET`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      setReasonNameList(response.data.lastEntryNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchLeaveType = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');


      const data = {
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/LeaveTypeGET`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      setLeaveType(response.data.lastEntryNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const MaxReqNo = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');


      const data = {
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/leave_reqsRouter/MaxReqNo`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      setleaveNumber(response.data.maxRequestNumber + 1)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const requestId = queryParams.get('id');
      if (requestId) {
        try {
          const data = await fetchLeaveData(requestId);
          console.log(data)
          setemployeeName(data?.employee_name);
          setleaveNumber(data.request_number);
          setleaveDate(dayjs(data.request_date));
          setLoanType(data.request_loan_purpose);
          setFromDate(dayjs(data.request_fromdate));
          setToDate(dayjs(data.request_todate));
          setReason(data.leave_reason);
          setRemark(data.request_remarks);
          setEditID(data?._id);
          setdata(data?.status)
          setamount(data?.request_amount)
          setInstallment(data?.request_installment)
          setInterest(data?.request_interest)
          setguarantors(data?.guarantors[0]);
          setcheque(data?.cheque);
          setbank(data?.bank);

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else if (location.state && location.state.data) {
        const data = location?.state?.data;
        console.log(data)
        setemployeeName(data?.employee_name);
        setleaveNumber(data.request_number);
        setleaveDate(dayjs(data.request_date));
        setLoanType(data.request_loan_purpose);
        setFromDate(dayjs(data.request_fromdate));
        setToDate(dayjs(data.request_todate));
        setReason(data.leave_reason);
        setRemark(data.request_remarks);
        setEditID(data?._id);
        setdata(data?.status)
        setamount(data?.request_amount)
        setInstallment(data?.request_installment)
        setInterest(data?.request_interest)
        setguarantors(data?.guarantors[0]);
        setcheque(data?.cheque);
        setbank(data?.bank);
      }
      else {
        console.log("Still have location?")
        const urlParams = new URLSearchParams(window.location.search);
        const dataParam = urlParams.get('data');
        if (dataParam) {
          const data = JSON.parse(decodeURIComponent(dataParam));
          setemployeeName(data?.data?.employee_name);
          setleaveNumber(data?.data.request_number);
          setleaveDate(dayjs(data?.data.request_date));
          setLoanType(data?.data.request_loan_purpose);
          setFromDate(dayjs(data?.data.request_fromdate));
          setToDate(dayjs(data?.data.request_todate));
          setReason(data?.data.leave_reason);
          setRemark(data?.data.request_remarks);
          setEditID(data?.data?._id);
          setdata(data?.status)
          setamount(data?.data?.request_amount)
          setInstallment(data?.data?.request_installment)
          setInterest(data?.data?.request_interest)
          setguarantors(data?.data?.guarantors[0]);
          setcheque(data?.data?.cheque);
          setbank(data?.data?.bank);
        }
      }
    };
    fetchData(); // Call the asynchronous function
  }, [location.state]);

  useEffect(() => {
    EmpListGET();
    fetchLeaveType();
    ReasonsGET();
    // MaxReqNo();
  }, [])

  const getSelectedDays = () => {
    if (!FromDate || !ToDate) return '0';

    const fromDateString = FromDate.format('DD/MM/YYYY');
    const toDateString = ToDate.format('DD/MM/YYYY');

    const fromParts = fromDateString.split('/');
    const toParts = toDateString.split('/');

    const fromDate = new Date(parseInt(fromParts[2]), parseInt(fromParts[1]) - 1, parseInt(fromParts[0]));
    const toDate = new Date(parseInt(toParts[2]), parseInt(toParts[1]) - 1, parseInt(toParts[0]));

    const differenceMs = toDate - fromDate;

    const differenceDays = differenceMs / (1000 * 60 * 60 * 24);
    return differenceDays + 1;
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '2px solid',
    boxShadow: 24,
    padding: 16,
    minWidth: 300,
    maxWidth: 500,
    textAlign: 'center'
  };
  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  const getFilteredOptions = () => {
    // EmpList setemployeeName employeeName
    return EmpList.filter(option => option._id !== employeeName?._id);
  };

  const getFilteredOptions2 = () => {
    // EmpList setemployeeName employeeName
    return EmpList.filter(option => option._id !== guarantors?._id);
  };

  useEffect(() => {
    if (Installment > 0) {
      const months = Math.ceil(amount / Installment); // Get number of months needed
      setToDate(dayjs(FromDate).add(months, 'month')); // Update ToDate based on FromDate
    }
  }, [Installment, amount, FromDate]);

  return (
    <Container sx={{ marginTop: '20px' }} ref={lastContentRef}>
      <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', width: '410px', fontSize: '20px' }}>
        Update Loan Request
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: '30%' }}>
        </Box>
        <div style={{ padding: '10px' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Paper elevation={3} style={{ padding: '15px' }}>
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Loan Request Number</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <input
                          type="number"
                          readOnly
                          value={leaveNumber}
                          onChange={(e) => setleaveNumber(e.target.value)}
                          style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '60%' }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} style={{ height: 'fit-content' }}>
                      <FormControl >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={leaveDate}
                            onChange={(date) => setleaveDate(date)}
                            label={'Request Date'}
                            format="DD/MM/YYYY" // Set the desired date format here
                            slotProps={{ textField: { size: 'small' } }}
                          />
                        </LocalizationProvider>
                      </FormControl>

                    </Grid>
                  </Grid>
                </div>
                <Divider /> {/* Divider */}
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Employee Name</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          value={employeeName}
                          onChange={(e, newValue) => setemployeeName(newValue)}
                          options={getFilteredOptions2()}
                          getOptionLabel={(option) => (option ? option.full_name : "")}
                          isOptionEqualToValue={(option, value) => option._id === value._id}
                          filterOptions={(options, state) => {
                            const inputValue = state.inputValue.toLowerCase();
                            return options.filter(option =>
                              option.full_name && option.full_name.toLowerCase().includes(inputValue)
                            );
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              {option.full_name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Employee"
                              inputProps={{
                                ...params.inputProps,
                                'aria-label': 'Select Employee',
                                style: { paddingTop: '0px' },
                              }}
                              variant="outlined"
                              size="small"
                            />
                          )}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>

                  </Grid>
                </div>
                <Divider /> {/* Divider */}
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Guarantor</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          value={guarantors}
                          onChange={(event, newValue) => {
                            setguarantors(newValue);
                          }}
                          options={getFilteredOptions()}
                          getOptionLabel={(option) => option?.full_name || ""}
                          getOptionSelected={(option, value) => option._id === value}
                          style={{ height: '38px', fontSize: '14px' }}
                          renderInput={(params) => <TextField {...params} variant="outlined"

                            inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                            sx={{
                              "& .MuiInputBase-root": { height: "38px" },
                            }}
                          />}
                        />
                      </FormControl>
                    </Grid>

                  </Grid>
                </div>
                <Divider /> {/* Divider */}
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Loan Description</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <input
                          type="text"
                          value={LoanType}
                          onChange={(e) => setLoanType(e.target.value)}
                          style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '100%' }}
                        />
                      </FormControl>
                    </Grid>

                  </Grid>
                </div>
                <Divider /> {/* Divider */}
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Loan Period</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={FromDate}
                            onChange={(date) => setFromDate(date)}
                            label={'From'}
                            format="DD/MM/YYYY" // Set the desired date format here
                            slotProps={{ textField: { size: 'small' } }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'center' }}>
                      <Typography variant="body1"><CompareArrowsIcon /></Typography>
                    </Grid>
                    <Grid item xs={2} style={{ height: 'fit-content' }}>
                      <FormControl >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={ToDate}
                            onChange={(date) => setToDate(date)}
                            label={'To'}
                            format="DD/MM/YYYY" // Set the desired date format here
                            slotProps={{ textField: { size: 'small' } }}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} style={{ height: 'fit-content' }}>
                      <Typography variant="body1">Selected Days: {getSelectedDays()}</Typography>
                    </Grid>
                  </Grid>
                </div>
                <Divider /> {/* Divider */}
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Amount</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <input
                          type="number"
                          value={amount}
                          onChange={(e) => setamount(e.target.value)}
                          style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '60%' }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <Divider /> {/* Divider */}
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Installment</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <input
                          type="number"
                          value={Installment}
                          onChange={(e) => setInstallment(e.target.value)}
                          style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '60%' }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <Divider /> {/* Divider */}
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Interest %</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <input
                          type="number"
                          value={Interest}
                          onChange={(e) => setInterest(e.target.value)}
                          style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '60%' }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <Divider /> {/* Divider */}
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Bank</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <input
                          type="text"
                          value={bank}
                          onChange={(e) => setbank(e.target.value)}
                          style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '60%' }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <Divider /> {/* Divider */}
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Cheque No.</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <input
                          type="number"
                          value={cheque}
                          onChange={(e) => setcheque(e.target.value)}
                          style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '60%' }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <Divider /> {/* Divider */}
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Reason</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          value={Reason}
                          onChange={(event, newValue) => {
                            setReason(newValue);
                          }}
                          options={ReasonNameList}
                          getOptionLabel={(option) => option?.discription || ""}
                          getOptionSelected={(option, value) => option._id === value}
                          style={{ height: '38px', fontSize: '14px' }}
                          renderInput={(params) => <TextField {...params} variant="outlined"

                            inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                            sx={{
                              "& .MuiInputBase-root": { height: "38px" },
                            }}
                          />}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
                <Divider /> {/* Divider */}
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Remark</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <input
                          type="text"
                          value={Remark}
                          onChange={(e) => setRemark(e.target.value)}
                          style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '100%' }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '50%', left: '0', right: '0', margin: '0 auto' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
          <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={() => { navigate('/transaction/LoanReqList') }}>
            Cancel
          </Button>
          <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={() => { handleSave() }} >
            Save
          </Button>
        </div>
      </Paper>
      <Modal
        open={successModalOpen}
        onClose={handleSuccessModalClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#007bff' }}>
          <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
          <p id="success-modal-description">{actionMessage}</p>
          <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
        </div>
      </Modal>
      <Modal
        open={errorModalOpen}
        onClose={handleErrorModalClose}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
          <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
          <p id="error-modal-description">{actionMessage}.</p>
          <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
        </div>
      </Modal>
    </Container>
  );
}
