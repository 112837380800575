import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TextField, Autocomplete, Button, Grid, Paper, Select, MenuItem } from '@mui/material';
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import { useGridApiRef } from '@mui/x-data-grid';
import debounce from 'lodash/debounce';

const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function TransactionSalary() {
    const navigate = useNavigate();
    const [salMode, setsalMode] = useState('All');
    const [maindata, setmainData] = useState([]);

    const [data, setData] = useState([]);
    const [Month, setMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [printdata, setPrintData] = useState([]);
    const [mainprintdata, setmainPrintData] = useState([]);
    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [DivValues, setDivValues] = useState([]);

    const [totalRowCount, setTotalRowCount] = React.useState(0);
    const [searchString, setsearchString] = useState(null);
    const [queryOptions, setQueryOptions] = React.useState({});
    const [filterModel, setFilterModel] = React.useState({
        items: [],
        quickFilterValues: [],
    });
    const apiRef = useGridApiRef();
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });

    useEffect(() => {
        fetchAdvanceData();
    }, [Month, paginationModel, salMode]);

    useEffect(() => {
        if (salMode === 'CASH' || salMode === 'BANK') {
            console.log(salMode)
            const filteredArray = maindata.filter(item => {
                console.log(item); // This will log each employee object
                return item?.salary_mode === salMode; // Ensure to return the boolean result
            });
            setData(filteredArray);

            const filteredArrayPrint = mainprintdata.filter(item => {
                console.log(item); // This will log each employee object
                return item?.employee?.salary_mode === salMode; // Ensure to return the boolean result
            });
            setData(filteredArray);
            setPrintData(filteredArrayPrint);
        } else {
            setData(maindata); // Reset to the original data if salMode is not 'CASH' or 'BANK'
            setPrintData(mainprintdata);
        }
    }, [salMode, maindata]); // Make sure to include maindata as a dependency

    const handleDelete = (params) => {
        const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
        const INFO = data[rowIndex];

        setDeleteIndex(INFO._id);
        console.log(INFO._id)
        setOpenModal(true);
    };

    const handleEdit = (params, e) => {
        const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
        const INFO = data[rowIndex];
        const dataToSend = { data: { _id: data[rowIndex]?._id, month: Month, employee: INFO?.full_name } };

        if (e.metaKey || e.ctrlKey) {
            const dataString = encodeURIComponent(JSON.stringify(dataToSend));
            const url = `/transaction/EditSalary?data=${dataString}`;
            window.open(url, '_blank');
        } else {
            navigate('/transaction/EditSalary', { state: dataToSend });
        }
        // navigate('/transaction/EditSalary', { state: dataToSend });
    };

    const handleModalDelete = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/salart_handleRouter/SingleSalryDelete/${deleteIndex}`,
                {
                    Month
                }, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log('Delete API Response:', response.data);
            handleCloseModal();
            fetchAdvanceData();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const fetchAdvanceData = debounce(async (searchQuery) => {

        // const fetchAdvanceData = async () => {
        setsearchString(searchQuery);
        setLoading(true);
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            setData([]);
            setPrintData([]);
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salart_handleRouter/Salary_List`,
                {
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                    Month: Month,
                    salMode,
                    page: paginationModel.page + 1,
                    pageSize: paginationModel.pageSize,
                    seacrhString: searchQuery,
                    typeZ: 'SalList'
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            const dfdf = await extractEmployeeData(generateArrays(response.data.data), response.data.atdresults);
            setData(dfdf);
            setmainData(dfdf);
            const dfgg = await generateArrays(addAtdResultsToData(response.data.data, response.data.atdresults));
            setPrintData(dfgg);
            setmainPrintData(dfgg);
            setTotalRowCount(response.data.totalDocuments);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, 100); // Adjust debounce delay as needed

    function addAtdResultsToData(data, atdresults) {
        const newData = [];

        data.forEach(employeeData => {
            const updatedEmployeeData = { ...employeeData };

            const atdResults = atdresults
                .filter(atd => atd.employee_name?._id === updatedEmployeeData.employee?._id)
                .sort((a, b) => new Date(a?.dalatten_date) - new Date(b?.dalatten_date));

            updatedEmployeeData.atdresults = atdResults;

            newData.push(updatedEmployeeData);
        });

        return newData;
    }

    const generateArrays = (data) => {
        return data.map(item => {
            const earnings = item.salryhd_or_group.filter(head => head.salryhd_sign === "(+)");
            const deductions = item.salryhd_or_group.filter(head => head.salryhd_sign === "(-)");
            const eArray = item.salryhd_or_group.filter(head => head.salryhd_sign === "E");

            return {
                ...item,
                earnings,
                deductions,
                eArray
            };
        });
    };

    const baseColumns = [
        {
            field: "StaffPrint",
            headerName: "Staff Print",
            flex: 1,
            renderCell: (params) => (
                <Button onClick={() => handleStaffPrintRow(params)}>
                    <PrintIcon sx={{ color: '#616161' }} />
                </Button>
            )
        },
        {
            field: "Print",
            headerName: "Print",
            flex: 1,
            renderCell: (params) => (
                <Button onClick={() => handlePrintRow(params)}>
                    <PrintIcon sx={{ color: '#616161' }} />
                </Button>
            )
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="text"
                    color="error"
                    size="small"
                    sx={{ padding: '0px' }}
                    onClick={() => handleDelete(params)}
                >
                    <DeleteIcon fontSize="small" />
                </Button>
            )
        },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="text"
                    color="primary"
                    size="small"
                    sx={{ padding: '0px' }}
                    onClick={(e) => handleEdit(params, e)}
                >
                    <EditIcon fontSize="small" />
                </Button>
            )
        },
        { field: 'net_salary', headerName: 'Net Salary', width: 100 },
        { field: 'machine_code', headerName: 'Employee Code', width: 100 },
        { field: 'full_name', headerName: 'Name', width: 200 },
        { field: 'department_description', headerName: 'Department', width: 100 },
        { field: 'designation', headerName: 'Designation', width: 100 },
        { field: 'shiftHours', headerName: 'Duty Hours', width: 100 },
        { field: 'perday', headerName: 'Per Day', width: 100 },
        { field: 'perHour', headerName: 'Per Hours', width: 100 },
        // { field: 'Basic', headerName: 'Basic', width: 100 },
        // { field: 'HRA', headerName: 'HRA', width: 100 },
        // { field: 'Conv', headerName: 'Conv', width: 100 },
        // { field: 'Medi', headerName: 'Medical', width: 100 },
        // { field: 'Others', headerName: 'Others', width: 100 },

        { field: 'gross_salary', headerName: 'Gross Salary', width: 100 },
        { field: 'total_attendance', headerName: 'Payble Days', width: 100 },
        // { field: 'calculated_gross', headerName: 'Calculated Gross', width: 100 },
        { field: 'amt', headerName: 'OT Amount', width: 100 },
        { field: 'ot_hrs', headerName: 'OT Hours', width: 100 },
        { field: 'salaryMod', headerName: 'Salary Mode', width: 100 },
        // { field: 'less_advance', headerName: 'Less Advance', width: 100 },

        // { field: 'CalBasic', headerName: 'Calculated Basic', width: 100 },
        // { field: 'CalHRA', headerName: 'Calculated HRA', width: 100 },
        // { field: 'CalConv', headerName: 'Calculated Conv', width: 100 },
        // { field: 'CalMedi', headerName: 'Calculated Medical', width: 100 },
        // { field: 'CalOthers', headerName: 'Calculated Others', width: 100 },

        // { field: 'less_pf', headerName: 'Less PF', width: 100 },
        // { field: 'less_loan', headerName: 'Less Loan', width: 100 },
        // { field: 'less_esic', headerName: 'Less ESIC', width: 100 },
        // { field: 'total_less', headerName: 'Total Less', width: 100 },

    ];

    const additionalBankColumns = [
        { field: 'calculated_gross', headerName: 'Calculated Gross', width: 100 },
        { field: 'total_add', headerName: 'Total Add', width: 100 },
        { field: 'less_advance', headerName: 'Less Advance', width: 100 },
        { field: 'Basic', headerName: 'Actual Basic', width: 100 },
        { field: 'HRA', headerName: 'Actual HRA', width: 100 },
        { field: 'Conv', headerName: 'Actual Conv', width: 100 },
        { field: 'Medi', headerName: 'Actual Medical', width: 100 },
        { field: 'Others', headerName: 'Actual Others', width: 100 },
        { field: 'CalBasic', headerName: 'Calculated Basic', width: 100 },
        { field: 'CalHRA', headerName: 'Calculated HRA', width: 100 },
        { field: 'CalConv', headerName: 'Calculated Conv', width: 100 },
        { field: 'CalMedi', headerName: 'Calculated Medical', width: 100 },
        { field: 'CalOthers', headerName: 'Calculated Others', width: 100 },
        { field: 'PF_max_limit', headerName: 'PF Limit', width: 100 },
        { field: 'less_pf', headerName: 'Less PF', width: 100 },
        { field: 'PFfigure', headerName: 'Emplyr PF Contri', width: 100 },
        { field: 'less_loan', headerName: 'Less Loan', width: 100 },
        { field: 'OpeningLoan', headerName: 'Opening Loan', width: 100 },
        { field: 'BalLoan', headerName: 'Bal Loan', width: 100 },

        { field: 'less_esic', headerName: 'Less ESIC', width: 100 },
        { field: 'ESICfigure', headerName: 'Emplyr ESIC Contri', width: 100 },
        { field: 'total_less', headerName: 'Total Less', width: 100 },
        { field: 'PT', headerName: 'PT', width: 100 },
    ];

    const columns = salMode !== "CASH" ? [...baseColumns, ...additionalBankColumns] : baseColumns;


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        // Format date as desired (e.g., MM/DD/YYYY)
        const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        return formattedDate;
    };

    const getYear = (dateString) => {
        const date = new Date(dateString);
        // Format date as desired (e.g., MM/DD/YYYY)
        const Year = `${date.getFullYear()}`;
        return Year;
    };

    function amountInWordsRupees(amount) {
        const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        function convertToWords(num) {
            if (num < 10) return ones[num];
            if (num < 20) return teens[num - 10];
            const digit = num % 10;
            return tens[Math.floor(num / 10)] + (digit ? ' ' + ones[digit] : '');
        }

        function convertToWordsRupees(num) {
            if (num === 0) return 'Zero Rupees';

            const crore = Math.floor(num / 10000000);
            const lakh = Math.floor((num % 10000000) / 100000);
            const thousand = Math.floor((num % 100000) / 1000);
            const hundreds = Math.floor((num % 1000) / 100);
            const remaining = num % 100;

            let words = '';

            if (crore) words += convertToWords(crore) + ' Crore ';
            if (lakh) words += convertToWords(lakh) + ' Lakh ';
            if (thousand) words += convertToWords(thousand) + ' Thousand ';
            if (hundreds) words += convertToWords(hundreds) + ' Hundred ';
            if (remaining) words += convertToWords(remaining);

            return words.trim() + ' Rupees';
        }

        return convertToWordsRupees(Math.floor(amount));
    }

    function convertToIST(dateString) {
        const utcDate = new Date(dateString);

        utcDate.setHours(utcDate.getHours() + 5); // Add 5 hours for IST
        utcDate.setMinutes(utcDate.getMinutes() + 30); // Add 30 minutes for IST

        const formattedDate = utcDate.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        return formattedDate;
    }

    function getDaysInMonth(month, year) {
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const monthIndex = monthNames.indexOf(month);

        if (monthIndex === -1) {
            return "Invalid month name";
        }

        // Handle February for leap year
        return new Date(year, monthIndex + 1, 0).getDate();
    }

    const handleStaffPrintRow = async (params) => {
        const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
        const data = printdata[rowIndex];
        var PrintAllyear = await getYear(data?.atdresults[0]?.dalatten_date);
        const days = await getDaysInMonth(Month, PrintAllyear);

        const resultString = data?.leave_or_group?.map(item => `${item.leave_name.code} : ${item.nos}`).join('&nbsp &nbsp &nbsp');
        const printWindow = window.open('', '_blank');

        const EarnDeduct = data?.earnings?.map((earning, index) => {
            return `
            <tr key=${index}>
            <td>${earning?.salaryhead_name?.salaryhead_name}</td>
            <td>${earning?.salryhd_vlu}</td>
            <td>${earning?.amount}</td>
        </tr>
        `;
        }).join('');

        printWindow.document.write(`<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 20px;
                }
                .payslip-container {
                    width: 95%;
                    margin: 0 auto;
                    border: 1px solid black;
                    padding: 15px;
                }
                .header, .sub-header {
                    text-align: center;
                    font-weight: bold;
                }
                .sub-header {
                    margin-top: 5px;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                td, th {
                    border: 1px solid black;
                    padding: 5px;
                    font-size: 12px;
                }
                .info-table td {
                    padding: 5px;
                }
                .salary-table td, .salary-table th {
                    text-align: center;
                }
                .right-align {
                    text-align: right;
                }
                .bold {
                    font-weight: bold;
                }
                .footer {
                    margin-top: 10px;
                    text-align: center;
                    font-weight: bold;
                }
                .footer-note {
                    text-align: center;
                    font-size: 12px;
                    margin-top: 10px;
                }
            </style>
        </head>
        <body>
        
        <div class="payslip-container">
            <!-- Header Section -->
            <div class="header">
            ${DivValues?.div_mast}
            </div>
            <div class="sub-header">
                Pay Slip for the Month of ${Month}-${PrintAllyear}
            </div>
        
            <!-- Contact Information Section -->
            <div style="margin-top: 5px; margin-below: 5px; font-weight: bold; text-align: left; line-height: 1.4;">
            <p style="margin: 0;">Address: ${DivValues?.ac_place || ''}</p>
            <p style="margin: 0;">Phone: ${DivValues?.ac_pho || ''}</p>
            <p style="margin: 0;">Mobile: ${DivValues?.ac_mobno || ''}</p>
            <p style="margin: 0;">Email: ${DivValues?.ac_email || ''}</p>
            <p style="margin: 0;"><a href="${DivValues?.ac_wbsite || ''}" style="text-decoration: none; color: #007BFF;">${DivValues?.ac_wbsite}</a></p>
          </div>
          

            <!-- Employee Info Section -->
            <table class="info-table">
                <tr>
                    <td><strong>Employee ID:</strong> ${data.employee.machine_code || 'N/A'}</td>
                    <td><strong>P.F. No: ${data?.employee?.pfNumber || 'N/A'}</strong> </td>
                </tr>
                <tr>
                    <td><strong>Employee Name:</strong> ${data.employee.full_name || 'N/A'}</td>
                    <td><strong>ESIC No: ${data.employee.EsicNumber || 'N/A'}</strong> </td>
                </tr>
                <tr>
                    <td><strong>Department:</strong> ${data?.employee?.department?.discription || 'N/A'}</td>
                    <td><strong>Bank Name:</strong> ${data?.employee?.bank?.discription || 'N/A'}</td>
                </tr>
                <tr>
                    <td><strong>Designation:</strong> ${data?.employee?.designation?.discription || 'N/A'}</td>
                    <td><strong>Bank A/c:</strong> ${data?.employee?.bank_ac_no || 'N/A'}</td>
                </tr>
                <tr>
                    <td><strong>Date of Join:</strong> ${convertToIST(data?.employee?.joining || 'N/A')}</td>
                    <td><strong>PAN No:</strong> ${data?.employee?.pan || 'N/A'}</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <strong>Month Days:</strong> ${days} &nbsp;&nbsp;&nbsp;
                        ${resultString}
                    </td>
                </tr>
                ${data?.employee?.BenLeavesSum?.map(leave => `
                <tr>
                  <td><strong>Opening ${leave.description}:</strong> &nbsp${leave.initialLeaves.toFixed(2)}</td>
                  <td><strong>Balance ${leave.description}:</strong> &nbsp${leave.leftLeaves.toFixed(2)}</td>
                </tr>
              `).join('')}
            </table>
        
            <!-- Salary Breakdown Section -->
            <table class="salary-table" style="margin-top: 20px; width: 100%; border-collapse: collapse; font-family: Arial, sans-serif;">
  <thead style="background-color: #f2f2f2;">
  <tr>
  <th style="padding: 10px; border-bottom: 2px solid #ddd; width: 80px;">Salary</th>
  <th style="padding: 10px; border-bottom: 2px solid #ddd; width: 80px;">Earnings</th>
  <th style="padding: 10px; border-bottom: 2px solid #ddd; width: 120px;">Ded.</th>
  <th style="padding: 10px; border-bottom: 2px solid #ddd; width: 90px;">Total</th>
</tr>

  </thead>
  <tbody>
    ${Array(Math.max(data?.earnings?.length, data?.deductions?.length)).fill('').map((_, index) => `
      <tr style="border-bottom: 1px solid #ddd;">
        <!-- Earnings Name and Value -->
        <td style="padding: 8px; text-align: center;">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <span style="width: 80px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              ${data?.earnings[index]?.salaryhead_name?.salaryhead_name || 'N/A'}
            </span>
            <span>
              ${data?.earnings[index]?.salryhd_vlu || 0}
            </span>
          </div>
        </td>
    
        <!-- Earnings Amount -->
        <td style="padding: 8px; text-align: center;">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <span style="width: 80px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              ${data?.earnings[index]?.salaryhead_name?.salaryhead_name || 'N/A'}
            </span>
            <span>
              ${data?.earnings[index]?.amount || 0}
            </span>
          </div>
        </td>
    
        <!-- Deductions -->
        <td style="padding: 8px; text-align: center;">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <span style="width: 80px; white-space: nowrap; ">
              ${data?.deductions[index]?.salaryhead_name?.salaryhead_name || 'N/A'}
            </span>
            <span>
              ${data?.deductions[index]?.amount || 0}
            </span>
          </div>
        </td>
    
        <!-- Total Column -->
        <td style="padding: 4px; width: 100px; border: 1px solid #ccc; background-color: #f9f9f9; border-radius: 8px;">
  <div style="display: flex; justify-content: space-between; align-items: center; height: 100%;">
    ${index === 0 ? `<span style="font-weight: bold; color: #4CAF50; width: 70px;">Total Earnings</span><span>${data?.calculated_gross}</span>` : ''}
    ${index === 1 ? `<span style="font-weight: bold; color: #f44336; width: 70px;">Total Ded.</span><span>${data?.total_less}</span>` : ''}
    ${index === 2 ? `<span style="font-weight: bold; color: #2196F3; width: 70px;">Net Salary</span><span>${data?.net_salary}</span>` : ''}
  </div>
</td>

      
    
      </tr>
    `).join('')}
  </tbody>
</table>

          
          



          
        
            <!-- Footer Section -->
            <div class="footer">
                NET PAY (IN WORDS): ${amountInWordsRupees(data.net_salary)}.
            </div>
            <div class="footer-note">
                Computer generated payslip signature not required
            </div>
        </div>
        
        </body>
        </html>
        `)
        printWindow.document.close();
    }

    const handlePrintRow = (params) => {
        const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
        const data = printdata[rowIndex];
        console.log('Printing row data:', data);
        const com_name = localStorage.getItem('com_name');
        const div_code = localStorage.getItem('div_code');
        var PrintAllyear = getYear(data?.atdresults[0]?.dalatten_date);
        const loanarray = data?.employee?.loangroup;
        const openingloan = loanarray?.reduce((total, item) => total + (item?.loanbalance || 0), 0);
        const OtAM = data?.atdresults?.reduce((total, item) => total + (item?.otamount || 0), 0);

        // const rowsHTML = data?.atdresults.map(row => {
        //     // const isNight = row.dalatten_atdtype?.discription === 'Night';
        //     const isNight = row.dalatten_shiftnm?.shift_name?.toLowerCase().includes('night');

        //     return `
        //     <tr class="contract_wrapper">
        //         <td>${formatDate(row?.dalatten_date)}</td>
        //         <td>${isNight ? 'X' : (row.dalatten_intime || '-')}</td>  
        //         <td>${isNight ? 'X' : (row.dalatten_outtime || '-')}</td> 
        //         <td>${isNight ? (row.dalatten_intime || '-') : 'X'}</td>  
        //         <td>${isNight ? (row.dalatten_outtime || '-') : 'X'}</td> 
        //         <td>${row.dalatten_atdtype.discription}</td>
        //         <td>${row.dalatten_total_hour}</td>
        //         <td>${row.dalatten_overtime?.toFixed(2)}</td>
        //         <td>${row?.dalatten_remarks ? row.dalatten_remarks : "N/A"}</td>
        //     </tr>
        // `;
        // }).join('');

        const rowsHTML = data?.atdresults.map((row, index, allRows) => {
            const isNight = row.dalatten_shiftnm?.shift_name?.toLowerCase().includes('night');
            const currentDate = formatDate(row?.dalatten_date);

            // Check if there is another record for the same date (day/night scenario)
            const sameDateRecords = data?.atdresults?.filter(r => formatDate(r.dalatten_date) === currentDate);
            const isMultipleForSameDate = sameDateRecords.length > 1;

            const shiftType = isMultipleForSameDate ? 'Day/Night' : 'N/A';

            return `
                <tr class="contract_wrapper">
                    <td>${currentDate}</td>
                    <td>${isNight ? 'X' : (row.dalatten_intime || '-')}</td>  
                    <td>${isNight ? 'X' : (row.dalatten_outtime || '-')}</td> 
                    <td>${isNight ? (row.dalatten_intime || '-') : 'X'}</td>  
                    <td>${isNight ? (row.dalatten_outtime || '-') : 'X'}</td> 
                    <td>${row.dalatten_atdtype?.discription}</td>
                    <td>${row.dalatten_total_hour}</td>
                    <td>${row.dalatten_overtime?.toFixed(2)}</td>
                    <td>${shiftType}</td>
                </tr>
            `;
        }).join('');


        const cashBank = data?.employee?.salary_mode == 'BANK' ?
            `<tr>
            <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">PF Amount </left></td>
            <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.less_pf || 0} </right></td>

                <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">ESIC Amount</left></td>
                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.less_esic || 0} </right></td>

                                    </tr>
                                    <tr >
            <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Less TDS </left></td>
                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.less_tds || 0} </right></td>

                                       
                <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">PT </left></td>
               
                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.salryhd_or_group.find(
                item => item?.salaryhead_name?.salaryhead_name === "Professional Tax")?.amount || 0} </right></td>

                 </tr>` : ``

        const BankDetails = data?.employee?.salary_mode == 'BANK' ?
            `<tr>
             <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">PF Number </right></td>
             <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.pfNumber || '-'} </right></td>

             <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">ESIC Nmber</right></td>
             <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.EsicNumber || '-'} </right></td>
         </tr>
         <tr>
             <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">UAN Number</right></td>
             <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.uan_no || '-'} </right></td>

             <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Bank Name </right></td>
             <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.bank?.discription || '-'} </right></td>
         </tr>
                 <tr>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Bank Branch </right></td>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.bankBranch || '-'} </right></td>

                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Bank A/C No. </right></td>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.bank_ac_no || '-'} </right></td>
             </tr>
             <tr>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Bank IFSC Code </right></td>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.ifsc_code || '-'} </right></td>

                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800"></right></td>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800"></right></td>
             </tr>`: ``

        // Example: Print row data in a new window
        const printWindow = window.open('', '_blank');
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${data.employee.full_name}</title>
            <style>
                /* Include your CSS styles here */
                body { 
                    font-size: 12px; 
                }
                @media print {
                    @page{
                        margin-top: 10px;
                        margin-bottom: 10px;
                        margin-left: 2em;
                        margin-right: 2em;
                    }
                    table tr.tx_invoice > td{
                        background-color: #c9c7c1 !important;
                    }
                    table{
                        width: 100%;
                    }
                    table tfoot{
                        page-break-inside: always;
                    }
                    /* Inline scoped styles */
                    .tabler1 td, .tabler1 th {
                        padding: 1px;
                        line-height: 1.42857143;
                        vertical-align: center;
                    }
                    .tableOutEntry2 td, .tableOutEntry2 th {
                        padding: 1px;
                        line-height: 1.42857143;
                        vertical-align: center;
                    }
                    .tableAddLess3 td, .tableAddLess3 th {
                        padding: 1px;
                        line-height: 1.42857143;
                        vertical-align: center;
                    }
                    .tableOut4 td, .tableOut4 th {
                        padding: 1px;
                        line-height: 1;
                        vertical-align: center;
                    }
                    .gray5{
                        background-color: #c9c7c1;
                    }
                    .header-container {
                        position: relative;
                        padding: 0px;
                        text-align: center;
                    }
                    .header-container h3 {
                        margin: 0;
                    }
                    .hr-text {
                        position: absolute;
                        below: 10px;
                        right: 30px;
                        font-size: 12px;
                        font-weight: bold;
                        color: #333;
                    }
                }
            </style>
        </head>
        <body>
        <div class="header-container">
        <h3></h3>
    </div>   
            <div class="page-wrapper-row full-height"> 
                <div class="page-wrapper-middle">
                    <div class="page-container">
                        <div class="page-content-wrapper">
                            <div style="width: 95%;margin: 2em" align="center" >

                           
                                <table border="1" class="tabler1" style="width: 100%;">
                                    <tr>
                                        <td colspan="4"><center style="font-size: 25px;font-family:Georgia, 'Times New Roman', Times, serif;font-weight: 1000">${JSON.parse(div_code)}-${Month}-${PrintAllyear}</center></td>
                                    </tr>
                                    <tr>
                                        <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Candidate</left></td>
                                        <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.employee.full_name}</left></td>
                                        <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Gross Salary</left></td>
                                        <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.gross_salary}</left></td>
                                    </tr>
                                    <tr >
                                        <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Department </left></td>
                                        <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.department?.discription || 'N/A'}</left></td>

                                        <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Machine</left></td>
                                        <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.employee?.machine?.machine_name || 'N/A'}</left></td>

                                    </tr>
                                    <tr >
                                        <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Shift</left></td>
                                        <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.default_shift?.shift_name}(${data?.employee?.default_shift?.total_hour || 'N/A'}</left></td>

                                        <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Attendance</left></td>
                                        <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.total_attendance}</left></td>
                                    </tr>
                                    ${BankDetails}
                                    ${cashBank}
                                    <tr >
                                    <td><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Calc. Salary</right></td>
                                    <td><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.calculated_gross}</right></td>
                                        <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Kharchi</right></td>
                                         <td><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.less_advance}</right></td>

                                        
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Opening Loan</right></td>
                                        <td><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${openingloan + data?.less_loan}</right></td>
                                    </tr>
                                    <tr>
                                        <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Total OT Hrs</right></td>
                                        <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.ot_hrs?.toFixed(2)}</right></td>
                                        <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Loan Ded.</right></td>
                                        <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.less_loan}</right></td>
                                    </tr>
                                    <tr>
                                        <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Total OT Amt</right></td>
                                        <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.amt || 0} </right></td>

                                        <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Bal Loan</right></td>
                                        <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${openingloan} </right></td>
                                    </tr>
                                   
                                    <tr>
                                        <td valign="bottom" colspan="3" style="font-size: 13px;border-right: none;">
                                            <b style="float: left">${amountInWordsRupees(data.net_salary)}.</b>
                                        </td>
                                        <td valign="bottom" colspan="1" style="font-size: 16px;border-left: none;">
                                            <b style="float: right;font-size: 14px">Net Payable : ${data.net_salary}</b>
                                        </td>
                                    </tr>
                                </table>
                                <table border="1" id="table1" style="width: 100%">
                                    <th>Date</th>
                                    <th>Day In</th>
                                    <th>Day Out</th>
                                    <th>Night In</th>
                                    <th>Night Out</th>
                                    <th>Atd Type</th>
                                    <th>Hours</th>
                                    <th>OT</th>
                                    <th>Remarks</th>
                                    ${rowsHTML}
                                </table>
                            </div>
                        </div>
                    </div>
                </div> 
                <span class="hr-text">Print By ${userSchema?.usrnm || ''}</span>
            </div>
        </body>
        </html>
    `);
        printWindow.document.close();
        // printWindow.print();
    };

    const handlePrintAll = (dataArray) => {
        console.log('Printing row data:', dataArray);
        const com_name = localStorage.getItem('com_name');
        const div_code = localStorage.getItem('div_code');
        let combinedHTML = ''; // Initialize an empty string to combine HTML for all pages
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);

        dataArray?.forEach((data, index) => {
            var PrintAllyear = getYear(data?.atdresults[0]?.dalatten_date);
            const loanarray = data?.employee?.loangroup;
            const openingloan = loanarray?.reduce((total, item) => total + (item?.loanbalance || 0), 0);
            const OtAM = data?.atdresults?.reduce((total, item) => total + (item?.otamount || 0), 0);

            // const rowsHTML = data?.atdresults.map(row => {
            //     // const isNight = row.dalatten_atdtype?.discription === 'Night';
            //     const isNight = row.dalatten_shiftnm?.shift_name?.toLowerCase().includes('night');

            //     return `
            //     <tr class="contract_wrapper">
            //         <td>${formatDate(row?.dalatten_date)}</td>
            //         <td>${isNight ? 'X' : (row.dalatten_intime || '-')}</td>  
            //         <td>${isNight ? 'X' : (row.dalatten_outtime || '-')}</td> 
            //         <td>${isNight ? (row.dalatten_intime || '-') : 'X'}</td>  
            //         <td>${isNight ? (row.dalatten_outtime || '-') : 'X'}</td> 
            //         <td>${row.dalatten_atdtype.discription}</td>
            //         <td>${row.dalatten_total_hour}</td>
            //         <td>${row.dalatten_overtime?.toFixed(2)}</td>
            //         <td>${row?.dalatten_remarks ? row.dalatten_remarks : "N/A"}</td>
            //     </tr>
            // `;
            // }).join('');

            const rowsHTML = data?.atdresults.map((row, index, allRows) => {
                const isNight = row.dalatten_shiftnm?.shift_name?.toLowerCase().includes('night');
                const currentDate = formatDate(row?.dalatten_date);

                // Check if there is another record for the same date (day/night scenario)
                const sameDateRecords = data?.atdresults?.filter(r => formatDate(r.dalatten_date) === currentDate);
                const isMultipleForSameDate = sameDateRecords.length > 1;

                const shiftType = isMultipleForSameDate ? 'Day/Night' : 'N/A';

                return `
                    <tr class="contract_wrapper">
                        <td>${currentDate}</td>
                        <td>${isNight ? 'X' : (row.dalatten_intime || '-')}</td>  
                        <td>${isNight ? 'X' : (row.dalatten_outtime || '-')}</td> 
                        <td>${isNight ? (row.dalatten_intime || '-') : 'X'}</td>  
                        <td>${isNight ? (row.dalatten_outtime || '-') : 'X'}</td> 
                        <td>${row?.dalatten_atdtype?.discription}</td>
                        <td>${row.dalatten_total_hour}</td>
                        <td>${row.dalatten_overtime?.toFixed(2)}</td>
                        <td>${shiftType}</td>
                    </tr>
                `;
            }).join('');

            const cashBank = data?.employee?.salary_mode == 'BANK' ?
                `<tr>
                <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">PF Amount </left></td>
                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.less_pf || '-'} </right></td>
                    <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">ESIC Amount</left></td>
                    <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.less_esic || '-'} </right></td>

                                        </tr>
                                        <tr >
                <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Less TDS </left></td>
                    <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.less_tds || 0} </right></td>

                                           
                    <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">PT  </left></td>
                    <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.salryhd_or_group.find(
                    item => item?.salaryhead_name?.salaryhead_name === "Professional Tax")?.amount || 0} </right></td>

                     </tr>` : ``

            const BankDetails = data?.employee?.salary_mode == 'BANK' ?
                `<tr>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">PF Number </right></td>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.pfNumber || '-'} </right></td>

                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">ESIC Nmber </right></td>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.EsicNumber || '-'} </right></td>
             </tr>
             <tr>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">UAN Number </right></td>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.uan_no || '-'} </right></td>
                 
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Bank Name </right></td>
                 <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.bank?.discription || '-'} </right></td>
             </tr>
                     <tr>
                     <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Bank Branch </right></td>
                     <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.bankBranch || '-'} </right></td>
                     <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Bank A/C No. </right></td>
                     <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.bank_ac_no || '-'} </right></td>
                 </tr>
                 <tr>
                     <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Bank IFSC Code </right></td>
                     <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.ifsc_code || '-'} </right></td>
                     <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800"></right></td>
                     <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800"></right></td>
                 </tr>`: ``
            // Append HTML for the current page to the combinedHTML
            combinedHTML += `
            <!-- HTML for page ${data.employee?.full_name} -->
            <div class="header-container">
        <h3></h3>
    </div>  
            <div class="page-wrapper-row full-height"> 
            <div class="page-wrapper-middle">
                <div class="page-container">
                    <div class="page-content-wrapper">

                        <div style="width: 95%;margin: 2em" align="center" >
                            <table border="1" class="tabler1" style="width: 100%;">
                                <tr>
                                    <td colspan="4"><center style="font-size: 25px;font-family:Georgia, 'Times New Roman', Times, serif;font-weight: 1000">${JSON.parse(div_code)}-${Month}-${PrintAllyear}</center></td>
                                </tr>
                                <tr>
                                <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Candidate  </left></td>
                                <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.employee.full_name}</left></td>
                                <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Gross Salary </left></td>
                                <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.gross_salary}</left></td>
                            </tr>
                            <tr >
                                <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Department   </left></td>
                                <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.department?.discription}</left></td>

                                <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Machine </left></td>
                                <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.employee?.machine?.machine_name}</left></td>
                            </tr>
                            <tr >
                                <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Shift   </left></td>
                                <td ><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.employee?.default_shift?.shift_name}(${data?.employee?.default_shift?.total_hour}</left></td>
                                
                                <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Attendance </left></td>
                                <td><left style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.total_attendance}</left></td>
                            </tr>
                            ${BankDetails}
                            ${cashBank}
                            <tr >
                            <td><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Calc. Salary  </right></td>
                            <td><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.calculated_gross}</right></td>
                                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Kharchi </right></td>
                                <td><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.less_advance}</right></td>
                                
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Opening Loan  </right></td>
                                <td><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${openingloan + data?.less_loan}</right></td>
                            </tr>
                            <tr>
                                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Total OT Hrs  </right></td>
                                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.ot_hrs?.toFixed(2)}</right></td>
                                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Loan Ded. </right></td>
                                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data.less_loan}</right></td>
                            </tr>
                            <tr>
                                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Total OT Amt  </right></td>
                                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${data?.amt || 0} </right></td>
                                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">Bal Loan</right></td>
                                <td ><right style="font-size: 16px;font-family:'Courier New', Courier, monospace;font-weight: 800">${openingloan} </right></td>
                            </tr>
                           
                                <tr>
                                    <td valign="bottom" colspan="3" style="font-size: 13px;border-right: none;">
                                        <b style="float: left">${amountInWordsRupees(data.net_salary)}.</b>
                                    </td>
                                    <td valign="bottom" colspan="1" style="font-size: 16px;border-left: none;">
                                        <b style="float: right;font-size: 14px">Net Payable : ${data.net_salary}</b>
                                    </td>
                                </tr>
                            </table>
                            <table border="1" id="table1" style="width: 100%">
                                <th>Date</th>
                                <th>Day In</th>
                                <th>Day Out</th>
                                <th>Night In</th>
                                <th>Night Out</th>
                                <th>Atd Type</th>
                                <th>Hours</th>
                                <th>OT</th>
                                <th>Remarks</th>
                                ${rowsHTML}
                            </table>
                        </div>
                        <span class="hr-text">Print By ${userSchema?.usrnm || ''}</span>
                    </div>
                </div>
            </div> 
        </div>
        `;
            if (index !== dataArray.length - 1) {
                combinedHTML += '<div style="page-break-after: always;"></div>';
            }
        });

        // Example: Print combined HTML in a new window
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Print Document</title>
            <style>
            /* Include your CSS styles here */
            body { 
                font-size: 12px; 
            }
            @media print {
                @page{
                    margin-top: 10px;
                    margin-bottom: 10px;
                    margin-left: 2em;
                    margin-right: 2em;
                }
                table tr.tx_invoice > td{
                    background-color: #c9c7c1 !important;
                }
                table{
                    width: 100%;
                }
                table tfoot{
                    page-break-inside: always;
                }
                /* Inline scoped styles */
                .tabler1 td, .tabler1 th {
                    padding: 1px;
                    line-height: 1.42857143;
                    vertical-align: center;
                }
                .tableOutEntry2 td, .tableOutEntry2 th {
                    padding: 1px;
                    line-height: 1.42857143;
                    vertical-align: center;
                }
                .tableAddLess3 td, .tableAddLess3 th {
                    padding: 1px;
                    line-height: 1.42857143;
                    vertical-align: center;
                }
                .tableOut4 td, .tableOut4 th {
                    padding: 1px;
                    line-height: 1;
                    vertical-align: center;
                }
                .gray5{
                    background-color: #c9c7c1;
                }
                .header-container {
                    position: relative;
                    padding: 0px;
                    text-align: center;
                }
                .header-container h3 {
                    margin: 0;
                }
                .hr-text {
                    position: absolute;
                    below: 10px;
                    right: 30px;
                    font-size: 12px;
                    font-weight: bold;
                    color: #333;
                }
            }
        </style>
        </head>
        <body>
            ${combinedHTML} <!-- Combined HTML for all pages -->
        </body>
        </html>
    `);
        printWindow.document.close();
    };

    const handlePrintPack = (dataArray) => {
        const com_name = localStorage.getItem('com_name');
        const div_code = localStorage.getItem('div_code');
        let combinedHTML = ''; // Initialize an empty string to combine HTML for all pages
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const Numberofprint = 1; // Set your number of prints here (replace this with the actual variable if needed)
        let skipx = 1 - 1; // Number of prints to skip

        dataArray?.forEach((data, index) => {
            var PrintAllyear = getYear(data?.atdresults[0]?.dalatten_date);
            const loanarray = data?.employee?.loangroup;
            const openingloan = loanarray?.reduce((total, item) => total + (item?.loanbalance || 0), 0);
            const OtAM = data?.atdresults?.reduce((total, item) => total + (item?.otamount || 0), 0);
            let printsForThisItem;

            // Create HTML for the current page
            let pageHTML = `
            <div class="content-wrapper">
                <div class="row">
                  <div class="col-md-12">
                    <section class="invoice">
                      <div class="row">
                        <div class="col-xs-12">
                          <table class="article">
                            <tr>
                              <td><b>Name</b></td>
                              <td colspan="3"><b>${data?.employee?.full_name}</b></td>
                            </tr>
                            <tr>
                            <td colspan="1"><b>Amount</b></td>
                            <td colspan="1"><b>${data?.gross_salary}</b></td>
                            <td colspan="1"><b>OT</b></td>
                            <td colspan="1"><b>${data?.amt || 0}</b></td>
                            </tr>
                            <tr>
                              <td><b>Kharchi</b></td>
                              <td colspan="3"><b>${data?.less_advance}</b></td>
                            </tr>
                            <tr>
                            <td><b>Loan</b></td>
                            <td colspan="3"><b>${data.less_loan}</b></td>
                          </tr>
                            <tr>
                              <td><b>PF</b></td>
                              <td colspan="3"><b>${data?.less_pf}</b></td>
                            </tr>
                            <tr>
                              <td><b>ESIC</b></td>
                              <td colspan="3"><b>${data?.less_esic}</b></td>
                            </tr>
                            <tr>
                              <td><b>PT</b></td>
                              <td colspan="3"><b>${data.salryhd_or_group.find(
                item => item?.salaryhead_name?.salaryhead_name === "Professional Tax")?.amount || 0}</b></td>
                            </tr>
                            <tr>
                              <td><b>Net</b></td>
                              <td colspan="3"><b>${data?.net_salary}</b></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            `;

            while (skipx > 0) {
                combinedHTML += `<div class="content-wrapper" style="float: left;
                margin-left: 41px;
                margin-right: 0px;
                width: 440px;
                height: 205px;
                margin-top: 0px;
                margin-bottom: 25px;
                page-break-inside: avoid;"></div>`;
                skipx--; // Decrease the skip counter after adding a blank page
            }

            // Append the HTML for the page the number of times specified by Numberofprint
            for (let i = 0; i < Numberofprint; i++) {
                combinedHTML += pageHTML;
            }
            // for (let i = 0; i < printsForThisItem; i++) {
            //     combinedHTML += pageHTML;
            // }
            // }
        });

        // Example: Print combined HTML in a new window
        const printWindow = window.open('', 'Salary Print');
        printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Accessories Per Pack Print</title>
          <link rel="stylesheet" href="path/to/your/css">
          <style>
            td {
              border: 1px solid #000000;
              padding: 5px;
                text-align: left;
            }
            tbody td {
              padding-top: 0;
              padding-bottom: 0;
            }
            .article {
                border-collapse: collapse; /* Remove gaps */
              float: left;
              margin-left: 41px;
              margin-right: 0px;
              width: 440px;
              height: 205px;
              margin-top: 0px;
              margin-bottom: 25px;
              page-break-inside: avoid;
            }
            @media print {
              @page {
                margin-top: 36px;
                margin-left: 5px;
                margin-right: 2px;
                margin-bottom: -5px;
              }
              table {
                page-break-inside: avoid;
                width: 440px;
              }
            }
          </style>
        </head>
        <body>
          ${combinedHTML} <!-- Combined HTML for all pages -->
        </body>
        </html>
        `);
        printWindow.document.close();
    };

    function extractEmployeeData(data, AtdData) {
        const OTAMount = 0;

        return data.map((employee, index) => {
            const atdResults = AtdData
                .filter(atd => atd.employee_name?._id == employee?.employee?._id);

            const OtAM = atdResults.reduce((total, item) => total + (item?.otamount || 0), 0);
            const loanarray = employee?.employee?.loangroup;
            const BalLoan = loanarray?.reduce((total, item) => total + (item?.loanbalance || 0), 0);
            // console.log("JI", employee)
            return {
                _id: employee?._id,
                id: index,
                salary_mode: employee.employee.salary_mode,
                BenLeavesSum: employee?.employee?.BenLeavesSum,
                machine_code: employee.employee.machine_code,
                full_name: employee.employee.full_name,
                salaryMod: employee?.employee?.salary_mode || 'Cash',
                department_description: employee?.employee?.department?.discription || '',
                designation: employee?.employee?.designation?.discription || '',
                shiftHours: employee?.employee?.default_shift?.total_hour || '',
                gross_salary: employee.gross_salary,
                total_attendance: employee.total_attendance,
                calculated_gross: employee.calculated_gross,
                perday: (Number(employee?.employee?.gross_sal) / Number(employee?.employee?.days || 26)).toFixed(2),
                perHour: ((Number(employee?.employee?.gross_sal) / Number(employee?.employee?.days || 26)) / Number(employee?.employee?.default_shift?.total_hour)).toFixed(2),
                amt: Number(employee?.amt?.toFixed(2)),
                // amt: OtAM,
                total_add: employee.total_add,
                less_advance: employee.less_advance,
                less_pf: employee.less_pf,
                less_loan: employee.less_loan,
                BalLoan: BalLoan || 0, 
                OpeningLoan: (BalLoan + employee?.less_loan) || 0,
                less_esic: employee.less_esic,
                PFfigure: employee.PFfigure,
                ESICfigure: employee.ESICfigure,
                total_less: Number(employee?.total_less?.toFixed(2)),
                net_salary: employee.net_salary,
                ot_hrs: Number(employee.ot_hrs?.toFixed(2)),
                PT: employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "Professional Tax"
                )?.amount || 0,
                PF_max_limit: employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "PF"
                )?.max_limit || 0,
                CalBasic: employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "BASIC"
                )?.amount || 0,
                CalHRA: employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "HRA"
                )?.amount || 0,
                CalConv: employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "CONV"
                )?.amount || 0,
                CalMedi: employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "MEDICAL"
                )?.amount || 0,
                CalOthers: employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "OTHERS"
                )?.amount || 0,

                Basic: employee?.employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "BASIC"
                )?.salryhd_vlu || 0,
                HRA: employee?.employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "HRA"
                )?.salryhd_vlu || 0,
                Conv: employee?.employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "CONV"
                )?.salryhd_vlu || 0,
                Medi: employee?.employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "MEDICAL"
                )?.salryhd_vlu || 0,
                Others: employee?.employee?.salryhd_or_group?.find(
                    item => item?.salaryhead_name?.salaryhead_name === "OTHERS"
                )?.salryhd_vlu || 0,
            };
        });
    }

    const generateFileName = () => {
        const now = new Date();
        const date = now.toLocaleDateString().replace(/\//g, '-'); // Format date as YYYY-MM-DD
        const time = now.toLocaleTimeString().replace(/:/g, '-');  // Format time as HH-MM-SS

        const prefix = 'SALARY SHEET';
        return `${prefix}_${Month}_${date}_${time}`;

        // return `AbsentReport_${date}_${time}`;
    };

    const Division_masterGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user_rights/Division_masterGET`,
                {
                    params: {
                        masterid: userSchema?.masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                        getDiv: JSON.parse(divIdString)
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDivValues(response?.data?.lastEntryNo[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        Division_masterGET();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ padding: '10px', }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                                Salary List
                            </Typography>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                                <Button onClick={(e) => {
                                    if (e.metaKey || e.ctrlKey) {
                                        const url = `/transaction/nosalry_rec`;
                                        window.open(url, '_blank');
                                    } else {
                                        navigate('/transaction/nosalry_rec')
                                    }
                                }}
                                    variant='contained' sx={{ borderRadius: '14px', padding: '8px 16px', m: 1 }}>Unprocessed Salaries</Button>

                                <Button
                                    variant="contained"
                                    // onClick={() => { navigate('/transaction/AddSalary') }}
                                    onClick={(e) => {
                                        if (e.metaKey || e.ctrlKey) {
                                            const url = `/transaction/AddSalary`;
                                            window.open(url, '_blank');
                                        } else {
                                            navigate('/transaction/AddSalary')
                                        }
                                    }}
                                    color="success"
                                    // disabled={printdata.length === 0}
                                    sx={{ borderRadius: '14px', padding: '8px 16px', m: 1 }}
                                >
                                    Add Salary
                                </Button>

                                <FormControl sx={{ m: 1, width: 120 }} size="small">

                                    <Select
                                        value={salMode}
                                        onChange={(e) => setsalMode(e.target.value)}
                                        sx={{ borderRadius: '14px' }}
                                    >
                                        <MenuItem value={'All'}>All</MenuItem>
                                        <MenuItem value={'CASH'}>Cash</MenuItem>
                                        <MenuItem value={'BANK'}>Bank</MenuItem>

                                    </Select>
                                </FormControl>

                                <Button
                                    variant="contained"
                                    onClick={() => { handlePrintAll(printdata) }}
                                    color="primary"
                                    disabled={printdata.length === 0}
                                    sx={{ borderRadius: '14px', padding: '8px 16px' }}
                                >
                                    Print All
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => { handlePrintPack(printdata) }}
                                    color="primary"
                                    disabled={printdata.length === 0}
                                    sx={{ borderRadius: '14px', padding: '8px 16px' }}
                                >
                                    Print Small
                                </Button>


                                <FormControl sx={{ m: 1, width: 120 }} size="small">

                                    <Select
                                        value={Month}
                                        onChange={(e) => setMonth(e.target.value)}
                                        sx={{ borderRadius: '14px' }}
                                    >
                                        {months.map(month => (
                                            <MenuItem key={month} value={month}>{month}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Autocomplete
                                    value={searchString}
                                    freeSolo
                                    // onInputChange={(event, value) => {
                                    //     fetchAdvanceData(value);
                                    // }}
                                    options={[]}
                                    // getOptionLabel={(option) => option.ACName || ''}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Search"
                                            variant="outlined"
                                            placeholder="Type to search..."
                                            size="small"
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    fetchAdvanceData(params.inputProps.value); // Called on 'Enter' keydown
                                                }
                                            }}
                                            onBlur={(event) => {
                                                fetchAdvanceData(params.inputProps.value); // Called on blur (input loses focus)
                                            }}
                                            sx={{ width: '150px' }} />
                                    }
                                    sx={{ "& .MuiInputBase-input": { fontSize: 14, } }}
                                // fullWidth
                                />
                            </div>
                        </div>
                        {/* <MUIDataTable
                            title={null}
                            data={data}
                            columns={columns}
                            options={options}
                        /> */}

                        <DataGrid
                            rows={data}
                            columns={columns}
                            initialState={{
                                ...data.initialState,
                                pagination: { paginationModel: { pageSize: 15 } },
                            }}
                            pageSizeOptions={[10, 25, 100, { label: "All", value: 99 }]}
                            apiRef={apiRef}
                            paginationMode={paginationModel.pageSize === 99 ? undefined : 'server'}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            rowCount={totalRowCount}

                            pagination
                            autoHeight
                            checkboxSelection={false}
                            disableSelectionOnClick
                            density="compact"
                            loading={loading}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    csvOptions: { fileName: generateFileName() },
                                },
                            }}
                        />
                    </Paper>
                </Grid>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                            Confirm Deletion
                        </Typography>
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to delete this item?
                        </Typography>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                                Cancel
                            </Button>
                            <Button onClick={handleModalDelete} variant="contained" color="error">
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Grid>
        </>
    );
}

export default TransactionSalary;
