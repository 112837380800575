import React, { useState, useEffect, useRef } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Drawer, Autocomplete, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Modal, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid, Box, Button, Paper, TextField, Divider, CircularProgress, Select, MenuItem } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import moment from 'moment';
import { TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './home.css';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import EditIcon from '@mui/icons-material/Edit';
import 'chartjs-plugin-zoom'; // Importing the zoom plugin
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, PointElement, LinearScale, LineElement, BarElement, Title, Tooltip, Legend } from "chart.js";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const Home = () => {

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate());

  const [selectionRange, setSelectionRange] = useState({
    startDate: thirtyDaysAgo,
    endDate: today,
    key: 'selection',
  });

  const [tempStartDate, setTempStartDate] = useState(thirtyDaysAgo);
  const [tempEndDate, setTempEndDate] = useState(today);
  const dateRangePickerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (ranges) => {
    // Update the tempStartDate and tempEndDate independently
    const { startDate, endDate } = ranges.selection;
    if (startDate && endDate) {
      if (startDate <= tempEndDate) {
        setTempStartDate(startDate);
      }
      if (endDate >= tempStartDate) {
        setTempEndDate(endDate);
      }
    }
  };

  const applyDateRange = () => {
    setSelectionRange({
      startDate: tempStartDate,
      endDate: tempEndDate,
      key: 'selection',
    });
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
      setIsOpen(false); // Close the date picker when clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDateRangePicker = () => {
    setIsOpen(!isOpen);
  };

  const formatDates = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [SHIFTselectionRange, setSHIFTSelectionRange] = useState({
    startDate: thirtyDaysAgo,
    endDate: today,
    key: 'SHIFTselection',
  });

  const [SHIFTtempStartDate, setSHIFTTempStartDate] = useState(thirtyDaysAgo);
  const [SHIFTtempEndDate, setSHIFTTempEndDate] = useState(today);
  const SHIFTdateRangePickerRef = useRef(null);
  const [SHIFTisOpen, setSHIFTIsOpen] = useState(false);

  const SHIFThandleSelect = (ranges) => {
    // Update the tempStartDate and tempEndDate independently
    const { startDate, endDate } = ranges.selection;
    if (startDate && endDate) {
      if (startDate <= SHIFTtempEndDate) {
        setSHIFTTempStartDate(startDate);
      }
      if (endDate >= SHIFTtempStartDate) {
        setSHIFTTempEndDate(endDate);
      }
    }
  };

  const SHIFTapplyDateRange = () => {
    setSHIFTSelectionRange({
      startDate: SHIFTtempStartDate,
      endDate: SHIFTtempEndDate,
      key: 'SHIFTselection',
    });
    setSHIFTIsOpen(false);
  };

  const SHIFThandleClickOutside = (event) => {
    if (SHIFTdateRangePickerRef.current && !SHIFTdateRangePickerRef.current.contains(event.target)) {
      setSHIFTIsOpen(false); // Close the date picker when clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', SHIFThandleClickOutside);
    return () => {
      document.removeEventListener('mousedown', SHIFThandleClickOutside);
    };
  }, []);

  const SHIFTtoggleDateRangePicker = () => {
    setSHIFTIsOpen(!SHIFTisOpen);
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [AttendanceReqdrawerOpen, setAttendanceReqdrawerOpen] = useState(false);
  const [TaskdrawerOpen, setTaskdrawerOpen] = useState(false);
  const [DepisLoading, setDepIsLoading] = useState(false); // State variable to manage loading state
  const [depdata, setdepData] = useState([]);
  const [DepdrawerOpen, setDepdrawerOpen] = useState(true);
  const [ShiftdrawerOpen, setShiftdrawerOpen] = useState(true);

  const [LoanData, setLoanData] = useState([]);
  const [BirthData, setBirthData] = useState([]);

  const [LoandrawerOpen, setLoandrawerOpen] = useState(false);
  const [BirthdrawerOpen, setBirthdrawerOpen] = useState(false);

  const [shiftpage, setshiftPage] = React.useState(0);
  const [shiftrowsPerPage, setshiftRowsPerPage] = React.useState(10); // Adjust as needed

  const shifthandleChangePage = (event, newPage) => {
    setshiftPage(newPage);
  };

  const shifthandleChangeRowsPerPage = (event) => {
    setshiftRowsPerPage(parseInt(event.target.value, 10));
    setshiftPage(0);
  };

  const [deppage, setdepPage] = React.useState(0);
  const [deprowsPerPage, setdepRowsPerPage] = React.useState(10); // Adjust as needed

  const dephandleChangePage = (event, newPage) => {
    setdepPage(newPage);
  };

  const dephandleChangeRowsPerPage = (event) => {
    setdepRowsPerPage(parseInt(event.target.value, 10));
    setdepPage(0);
  };

  const [reqData, setReqData] = useState([]);
  const [milli, setmilli] = useState('');
  const [AttendreqData, setAttendreqData] = useState([]);
  const [selectedDate, handleDateChange] = useState(moment());
  const [monthSummary, setMonthlySummary] = useState([]);
  const [Data, setData] = useState({});
  const [TodaySummary, setTodaySummary] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [Graphloading, setGraphloading] = useState(true); // Add loading state
  const [month, setmonth] = useState(''); // Add loading state
  const [CountData, setCountData] = useState(null); // Add loading state
  const [shiftdata, setshiftData] = useState([]);
  const [shiftisLoading, setshiftIsLoading] = useState(false); // State variable to manage loading state

  const [availableMonths, setAvailableMonths] = useState([
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [remark, setRemark] = useState('');
  const [status, setStatus] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [user, setUser] = useState('');
  const [userList, setuserList] = useState([]);

  const [lvID, setLvID] = useState('');
  const [EditID, setEditID] = useState();
  const [leaveno, setleaveno] = useState('');
  const [moduleName, setModuleName] = useState('');

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [ActionMessage, setActionMessage] = useState('');

  const [userRights, setUserRights] = useState(null); // Replaced state name
  const [administrator, setadministrator] = useState(false);
  const [EmpoyeeIID, setEmpoyeeIID] = useState(null);
  const [Taskdata, setTaskdata] = useState(null);
  const [TaskID, setTaskID] = useState(null);
  const [TaskStatus, setTaskStatus] = useState(null);
  const [TaskRemark, setTaskRemark] = useState(null);

  const [showSlider, setShowSlider] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openTaskStatusModal, setopenTaskStatusModal] = useState(false);

  const handleTaskStatus = async (data) => {
    handleOpenTaskStatusModal();
    setTaskID(data?._id?.id);
  }

  const handleOpenTaskStatusModal = () => {
    setopenTaskStatusModal(true);
  };

  const labels = shiftdata.map((item) => item.shiftName);
  const presentData = shiftdata.map((item) => item.Present);
  const absentData = shiftdata.map((item) => item.Absent);
  const NightData = shiftdata.map((item) => item.Night);
  const overtimeData = shiftdata.map((item) => item.overtimeTotal);

  const deplabels = depdata.map((item) => item.department);
  const deppresentData = depdata.map((item) => item.Present);
  const depabsentData = depdata.map((item) => item.Absent);
  const depNightData = depdata.map((item) => item.Night);
  const depovertimeData = depdata.map((item) => item.dalatten_overtime);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Present",
        data: presentData,
        backgroundColor: "rgba(76, 175, 80, 0.6)", // Green
        borderColor: "rgba(76, 175, 80, 1)", // Darker green
        borderWidth: 1,
      },
      {
        label: "Absent",
        data: absentData,
        backgroundColor: "rgba(244, 67, 54, 0.6)", // Red
        borderColor: "rgba(244, 67, 54, 1)", // Darker red
        borderWidth: 1,
      },
      {
        label: "Night",
        data: NightData,
        backgroundColor: "rgba(255, 193, 7, 0.6)", // Amber
        borderColor: "rgba(255, 193, 7, 1)", // Darker amber
        borderWidth: 1,
      },
      {
        label: "Overtime Total",
        data: overtimeData,
        backgroundColor: "rgba(33, 150, 243, 0.6)", // Blue
        borderColor: "rgba(33, 150, 243, 1)", // Darker blue
        borderWidth: 1,
      },
    ],
  };

  const Shiftoptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true, // This will hide the legend
      },
      title: {
        display: true,
        text: "Shift-wise Data",
      },
    },
    scales: {
      x: {
        ticks: {
          display: false, // This will hide the x-axis labels
        },
      },
      y: {
        ticks: {
          display: true, // This will hide the y-axis labels
        },
      },
    },
  };

  const DepchartData = {
    labels: deplabels,
    datasets: [
      {
        label: "Present",
        data: deppresentData,
        backgroundColor: "rgba(76, 175, 80, 0.6)", // Green
        borderColor: "rgba(76, 175, 80, 1)", // Darker green
        borderWidth: 2,
        fill: false, // Do not fill under the line
        tension: 0.1 // Smooth the line
      },
      {
        label: "Absent",
        data: depabsentData,
        backgroundColor: "rgba(244, 67, 54, 0.6)", // Red
        borderColor: "rgba(244, 67, 54, 1)", // Darker red
        borderWidth: 2,
        fill: false, // Do not fill under the line
        tension: 0.1 // Smooth the line
      },
      {
        label: "Night",
        data: depNightData,
        backgroundColor: "rgba(255, 193, 7, 0.6)", // Amber
        borderColor: "rgba(255, 193, 7, 1)", // Darker amber
        borderWidth: 2,
        fill: false, // Do not fill under the line
        tension: 0.1 // Smooth the line
      },
      {
        label: "Overtime Total",
        data: depovertimeData,
        backgroundColor: "rgba(33, 150, 243, 0.6)", // Blue
        borderColor: "rgba(33, 150, 243, 1)", // Darker blue
        borderWidth: 2,
        fill: false, // Do not fill under the line
        tension: 0.1 // Smooth the line
      },
    ],
  };

  // const DepchartData = {
  //   labels: deplabels,
  //   datasets: [
  //     {
  //       label: "Present",
  //       data: deppresentData,
  //       backgroundColor: "rgba(76, 175, 80, 0.6)", // Green
  //       borderColor: "rgba(76, 175, 80, 1)", // Darker green
  //       borderWidth: 1,
  //     },
  //     {
  //       label: "Absent",
  //       data: depabsentData,
  //       backgroundColor: "rgba(244, 67, 54, 0.6)", // Red
  //       borderColor: "rgba(244, 67, 54, 1)", // Darker red
  //       borderWidth: 1,
  //     },
  //     {
  //       label: "Night",
  //       data: depNightData,
  //       backgroundColor: "rgba(255, 193, 7, 0.6)", // Amber
  //       borderColor: "rgba(255, 193, 7, 1)", // Darker amber
  //       borderWidth: 1,
  //     },
  //     {
  //       label: "Overtime Total",
  //       data: depovertimeData,
  //       backgroundColor: "rgba(33, 150, 243, 0.6)", // Blue
  //       borderColor: "rgba(33, 150, 243, 1)", // Darker blue
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  const Depoptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true, // This will hide the legend
      },
      title: {
        display: true,
        text: "Department-wise Data",
      },
    },
    scales: {
      x: {
        ticks: {
          display: true, // This will hide the x-axis labels
        },
      },
      y: {
        ticks: {
          display: true, // This will hide the y-axis labels
        },
      },
    },
  };

  const handleCloseTaskStatusModal = () => {
    setTaskID(null);
    setTaskStatus(null);
    setTaskRemark(null);
    setopenTaskStatusModal(false);
  };

  function concatenateUrls(filenames, baseUrl) {
    return filenames.map(filename => baseUrl + filename);
  }

  const handleImageClick = async (imageUrls, imageIndex) => {
    setCurrentIndex(imageIndex);
    setSliderImages(await concatenateUrls(imageUrls, `${process.env.REACT_APP_API_URL}/uploads/`));
    console.log(imageUrls)
    setShowSlider(true);
  };

  const handleCloseSlider = () => {
    setShowSlider(false);
  };

  const settings = {
    customPaging: function (i) {
      return (
        <div>
          <img
            src={sliderImages[i]}
            alt=""
            style={{
              height: "50px",
              width: "50px",
              objectFit: "cover",
              borderRadius: "10px"
            }}
          />
        </div>
      );
    },
    dots: true,
    cssEase: "linear",
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex, // Start with the third image
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex)
  };

  useEffect(() => {
    const userSchemaString = localStorage.getItem('userSchema');
    const userSchema = JSON.parse(userSchemaString);
    // setUserDetail(userSchema);
    setEmpoyeeIID(localStorage.getItem('EmployeeIDD'))
    setadministrator(userSchema?.administrator === "Yes" ? true : false);
    setUserRights(userSchema?.admin ? userSchema.admin.split(/,\s*/) : [])
  }, []);

  function convertAndFormatDate(dateStr) {
    const dateObj = new Date(dateStr);
    const formattedDate = formatDate(dateObj);
    return formattedDate;
  }

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  const employeeIdByUser = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage

      // Make sure the endpoint is correct
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/task/employeeIdByUser`,
        {
          // Data to be sent in the request body
          divid: JSON.parse(divIdString),
          full_name: userSchema?.usrnm,
          mobile_no: userSchema?.phone_num,
        },
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      if (response?.data?.status) {
        return response?.data?._id;
      }
      else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };

  const GetEmployeeTasksDetails = async () => {
    try {
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const employeeIdByUserfg = await employeeIdByUser();
      const dataToSend = {
        task_employee: JSON.parse(localStorage.getItem('EmployeeIDD')) || employeeIdByUserfg,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/task/GetEmployeeTasksDetails`, dataToSend, {
        headers: {
          authorization: `Bearer ${token}`
        }
      })
      setTaskdata(response.data.summary);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (TaskdrawerOpen) {
      GetEmployeeTasksDetails();
    }
  }, [TaskdrawerOpen]);

  const handleTaskStatusSave = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/task/TaskStatusUpdateByEmp`,
        {
          id: TaskID,
          TaskStatus,
          TaskRemark,
          // user: userSchema?.usrnm,
          // compid: JSON.parse(companyIdString),
          // divid: JSON.parse(divIdString),
        },
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data.status) {
        GetEmployeeTasksDetails()
        handleCloseTaskStatusModal()
        setActionMessage(response.data.message)
        setSuccessModalOpen(true)
      }
      else {
        GetEmployeeTasksDetails()
        handleCloseTaskStatusModal()
        setActionMessage(response.data.message)
        setErrorModalOpen(true)
      }

    } catch (error) {
      GetEmployeeTasksDetails()
      handleCloseTaskStatusModal()
      setActionMessage('Some error occured.')
      setErrorModalOpen(true)
      console.error('Error fetching data:', error);
    }
  }

  const navigate = useNavigate();

  const chartSetting = {
    yAxis: [
      {
        label: 'Number of Students',
      },
    ],
    // width: 1250,
    height: 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-20px, 0)',
      },
    },
  };

  const dataset = Object.entries(Data).map(([date, values]) => ({
    date,
    present: values.present,
    absent: values.absent,
  }));

  const valueFormatter = (value) => value;

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    setmonth(currentMonth);
  }, []);

  const handleNextDate = () => {
    const nextDate = moment(selectedDate).add(1, 'day');
    handleDateChange(nextDate);
  };

  const handlePreviousDate = () => {
    const previousDate = moment(selectedDate).subtract(1, 'day');
    handleDateChange(previousDate);
  };

  const fetchReqData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);

      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const data = {
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        user: userSchema?.usrnm,
        masterid: userSchema?.masterid,
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/leave_reqsRouter/LeaveReqGET`, data, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      setReqData(response.data.lastEntryNo)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchLoanData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);

      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const data = {
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        user: userSchema?.usrnm,
        masterid: userSchema?.masterid,
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/leave_reqsRouter/LoanReqGET`, data, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      setLoanData(response.data.lastEntryNo)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchBirthData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);

      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const data = {
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        user: userSchema?.usrnm,
        masterid: userSchema?.masterid,
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/birthdays`, data, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      setBirthData(response.data.Birthdays)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCountData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);

      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const data = {
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        user: userSchema?.usrnm,
        masterid: userSchema?.masterid,
        full_name: userSchema?.usrnm,
        mobile_no: userSchema?.phone_num,
        taskEmp: JSON.parse(localStorage.getItem('EmployeeIDD'))
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/CountFun`, data, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      setCountData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchCountData();
  }, []);

  const AttendanceReqGET = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);

      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const data = {
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        user: userSchema?.usrnm,
        masterid: userSchema?.masterid,
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/leave_reqsRouter/AttendanceReqGET`, data, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      setAttendreqData(response.data.lastEntryNo)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchGraphData = async () => {
    try {
      setGraphloading(true);
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);

      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const currentDate = new Date();
      const data = {
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid,
        month: month ? month : currentDate.toLocaleString('default', { month: 'long' }),
        date: selectedDate.format('YYYY-MM-DD')
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/GetMonthsDateattendance`, data, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      // const newdata = await getPresentAndAbsentCount(response.data.Todaysummary);
      setData(response.data.GraphSum)
      setGraphloading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);

      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const currentDate = new Date();
      const data = {
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid,
        month: month ? month : currentDate.toLocaleString('default', { month: 'long' }),
        date: selectedDate.format('YYYY-MM-DD')
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/Getattendance`, data, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      setMonthlySummary(response.data.summary)
      setTodaySummary(response.data.Todaysummary)
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlesave = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const masterid = userSchema?.masterid;
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/leave_reqsRouter/LeaveReqUpdate`,
        {
          reqid: lvID,
          id: EditID,
          leaveno,
          moduleName,
          remark: remark,
          status: status,
          milli,
          selectedUser: status === 'Forward' ? selectedUser?.usrnm : '',
          user: userSchema?.usrnm,
          compid: JSON.parse(companyIdString),
          divid: JSON.parse(divIdString),
          masterid: userSchema?.masterid
        },
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data.status) {
        fetchReqData()
        handleCloseEditModal()
        setActionMessage(response.data.message)
        setSuccessModalOpen(true)
        setRemark('');
        setStatus('');
        setSelectedUser('');
      }
      else {
        fetchReqData()
        handleCloseEditModal()
        setActionMessage(response.data.message)
        setErrorModalOpen(true)
        setRemark('');
        setStatus('');
        setSelectedUser('');
      }

    } catch (error) {
      fetchReqData()
      handleCloseEditModal()
      setActionMessage('Some error occured.')
      setErrorModalOpen(true)
      console.error('Error fetching data:', error);
      setRemark('');
      setStatus('');
      setSelectedUser('');
    }
  }

  const fetchDepData = async () => {
    try {
      setDepIsLoading(true);
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/Getattendancedept`,
        {
          compid: JSON.parse(companyIdString),
          divid: JSON.parse(divIdString),
          masterid,
          // month: "May",
          startDate: selectionRange.startDate,
          endDate: selectionRange.endDate,
        },
        {
          params: {
          },
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );



      const countAttendanceTypesByDept = (summaryData) => {
        const attendanceCountByDept = [];

        summaryData.forEach(entry => {
          const deptNames = entry._id.deptname;
          const lvnames = entry._id.lvname;
          const count = entry.count;
          const overtime = entry.dalatten_overtime; // Assume this is the property for overtime

          let department;

          if (deptNames.length === 0) {
            department = "Unknown Department";
          } else {
            department = deptNames[0]; // Assuming deptname is always an array with a single value
          }

          // Find department in the attendanceCountByDept array
          let departmentIndex = attendanceCountByDept.findIndex(obj => obj.department === department);

          // If department does not exist in the array, create a new object for it
          if (departmentIndex === -1) {
            departmentIndex = attendanceCountByDept.length;
            attendanceCountByDept.push({
              department: department,
              Present: 0,
              Absent: 0,
              "Paid Holiday": 0,
              "Weekly Off": 0,
              "Half Day": 0,
              Night: 0,
              "Not Marked": 0,
              dalatten_overtime: 0
            });
          }

          // Increment the count for the corresponding type within the department object
          if (lvnames.length === 0) {
            attendanceCountByDept[departmentIndex]["Not Marked"] += count;
          } else {
            lvnames.forEach(lvname => {
              if (attendanceCountByDept[departmentIndex].hasOwnProperty(lvname)) {
                attendanceCountByDept[departmentIndex][lvname] += count;
              } else {
                attendanceCountByDept[departmentIndex]["Not Marked"] += count;
              }
            });
          }
          attendanceCountByDept[departmentIndex].dalatten_overtime += overtime;
        });

        // Format dalatten_overtime to two decimal places
        attendanceCountByDept.forEach(department => {
          department.dalatten_overtime = parseFloat(department.dalatten_overtime.toFixed(2));
        });

        return attendanceCountByDept;
      };

      const attendanceCountByDept = countAttendanceTypesByDept(response.data.summary);
      console.log('attendanceCountByDept', attendanceCountByDept);
      setdepData(attendanceCountByDept);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    finally {
      setDepIsLoading(false);
    }
  };

  const fetchShiftData = async () => {
    try {
      setshiftIsLoading(true);
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/GetattendanceShift`,
        {
          compid: JSON.parse(companyIdString),
          divid: JSON.parse(divIdString),
          masterid,
          // month: MonthName,
          startDate: SHIFTselectionRange.startDate,
          endDate: SHIFTselectionRange.endDate,
        },
        {
          params: {
          },
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );


      const calculateAttendanceCountByShiftAndCategory = (summaryData) => {
        const shifts = new Set(summaryData.map(entry => entry._id.shiftname?.[0] || "Not Marked"));
        const categories = new Set(summaryData.flatMap(entry => entry._id.lvname));
        categories.add("Not Marked");

        const attendanceCountByShiftAndCategory = {};
        shifts.forEach(shift => {
          attendanceCountByShiftAndCategory[shift] = {
            shiftName: shift,
            overtimeTotal: 0, // Initialize overtime total for each shift
          };
          categories.forEach(category => {
            attendanceCountByShiftAndCategory[shift][category] = 0;
          });
        });

        summaryData.forEach(entry => {
          const shiftName = entry._id.shiftname?.[0] || "Not Marked"; // Use "Not Marked" if shiftname is missing
          const shiftData = attendanceCountByShiftAndCategory[shiftName];
          const lvnames = entry._id.lvname;

          // Accumulate overtime total
          shiftData.overtimeTotal += parseFloat(entry?.dalatten_overtime) || 0;
          shiftData.overtimeTotal = parseFloat(shiftData.overtimeTotal.toFixed(2));

          // Check if lvnames exists and has values, otherwise use "Not Marked"
          if (!lvnames || lvnames.length === 0) {
            shiftData["Not Marked"] += entry.count;
          } else {
            lvnames.forEach(category => {
              shiftData[category] = (shiftData[category] || 0) + entry.count;
            });
          }
        });

        return attendanceCountByShiftAndCategory;
      };

      const attendanceCountByShiftAndCategory = await calculateAttendanceCountByShiftAndCategory(response.data.summary);
      const attendanceCountArray = Object.keys(attendanceCountByShiftAndCategory).map(key => attendanceCountByShiftAndCategory[key]);
      console.log('attendanceCountArray', attendanceCountArray)
      setshiftData(attendanceCountArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    finally {
      setshiftIsLoading(false);
    }
  };

  useEffect(() => {
    if (ShiftdrawerOpen) {
      fetchShiftData();
    }
  }, [SHIFTselectionRange, ShiftdrawerOpen]);

  useEffect(() => {
    if (DepdrawerOpen) {
      fetchDepData();
    }
  }, [selectionRange, DepdrawerOpen]);

  useEffect(() => {
    if (drawerOpen) {
      fetchReqData();
    }
  }, [drawerOpen]);

  useEffect(() => {
    if (LoandrawerOpen) {
      fetchLoanData();
    }
  }, [LoandrawerOpen]);

  useEffect(() => {
    if (BirthdrawerOpen) {
      fetchBirthData();
    }
  }, [BirthdrawerOpen]);


  useEffect(() => {
    if (AttendanceReqdrawerOpen) {
      AttendanceReqGET();
    }
  }, [AttendanceReqdrawerOpen]);

  useEffect(() => {
    const call = async () => {
      // console.log(selectedDate.format('YYYY-MM-DD'))
      await fetchData();
    }
    call();
  }, [month, selectedDate]);

  useEffect(() => {
    fetchGraphData()
  }, [month])

  const handleMonthChange = (event) => {
    setmonth(event.target.value);
  };

  const user_rights_securityGET = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');

      const data = {
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid
      }
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user_rights/user_rights_userGET`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      setuserList(response.data.lastEntryNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  function convertToIST(dateString) {
    const utcDate = new Date(dateString);

    utcDate.setHours(utcDate.getHours() + 5); // Add 5 hours for IST
    utcDate.setMinutes(utcDate.getMinutes() + 30); // Add 30 minutes for IST

    const formattedDate = utcDate.toLocaleDateString('en-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

    return formattedDate;
  }

  const NewreqData = reqData.map((item, index) => ({
    ...item,
    id: index,
    employee: item?.leave_request?.employee_name?.full_name,
    department: item?.leave_request?.employee_name?.department?.discription,
    nos: item?.leave_request?.request_nos,
    request_date: convertToIST(item?.leave_request?.request_date)

  }));

  const AttenNewreqData = AttendreqData.map((item, index) => ({
    ...item,
    id: index,
    employee: item?.atten_request?.employee_name?.full_name,
    department: item?.atten_request?.employee_name?.department?.discription,
    dalatten_shiftnm: item?.atten_request?.dalatten_shiftnm?.shift_name,
    intime: item?.atten_request?.dalatten_shiftnm?.in_time,
    outtime: item?.atten_request?.dalatten_shiftnm?.out_time,
    // nos: item?.atten_request?.request_nos,
    request_date: convertToIST(item?.atten_request?.dalatten_date),
  }));

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleEdit = (editdata) => {
    setleaveno(editdata?.sno)
    setModuleName(editdata.module_name)
    setEditID(editdata?._id);
    setLvID(editdata?.leave_request?._id || editdata?.atten_request?._id);
    setmilli(editdata?.request_datemilisecond);
    handleOpenEditModal()
  };

  const checkDateOverlap = (fromDate1, toDate1, fromDate2, toDate2) => {
    return !(toDate1 < fromDate2 || toDate2 < fromDate1);
  };

  const overlappingDepartments = new Set();

  NewreqData.forEach((item, index) => {
    const { department, leave_request } = item;
    const fromDate1 = new Date(leave_request?.request_fromdate);
    const toDate1 = new Date(leave_request?.request_todate);

    NewreqData.slice(index + 1).forEach((otherItem) => {
      if (otherItem.department === department) {
        const fromDate2 = new Date(otherItem.leave_request?.request_fromdate);
        const toDate2 = new Date(otherItem.leave_request?.request_todate);

        if (checkDateOverlap(fromDate1, toDate1, fromDate2, toDate2)) {
          overlappingDepartments.add(department);
        }
      }
    });
  });

  const NewreqLoanData = LoanData.map((item, index) => ({
    ...item,
    id: index,
    employee: item?.leave_request?.employee_name?.full_name,
    department: item?.leave_request?.employee_name?.department?.discription,
    nos: item?.leave_request?.request_nos,
    request_date: convertToIST(item?.request_date)
  }));

  NewreqLoanData.forEach((item, index) => {
    const { department, leave_request } = item;
    const fromDate1 = new Date(leave_request?.request_fromdate);
    const toDate1 = new Date(leave_request?.request_todate);

    NewreqLoanData.slice(index + 1).forEach((otherItem) => {
      if (otherItem.department === department) {
        const fromDate2 = new Date(otherItem.leave_request?.request_fromdate);
        const toDate2 = new Date(otherItem.leave_request?.request_todate);

        if (checkDateOverlap(fromDate1, toDate1, fromDate2, toDate2)) {
          overlappingDepartments.add(department);
        }
      }
    });
  });

  const columns = [
    // {
    //   field: "module_name",
    //   headerName: "Module Name",
    //   width: 150,
    //   renderCell: (params) => {
    //     const department = params.row.department;
    //     // const isHighlighted = multipleRequestsDepartments.has(department);

    //     return (
    //       <>
    //         {params.row.module_name}
    //       </>
    //     );
    //   }
    // },
    {
      field: "sno",
      headerName: "Request No.",
      width: 150,
      renderCell: (params) => {
        const department = params.row.department;
        // const isHighlighted = multipleRequestsDepartments.has(department);
        const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
        const editdata = reqData[rowIndex];
        const requestID = params.row.leave_request?._id;
        const requestNo = params.row.sno;
        const moduleName = params.row.module_name;

        let linkTo = '';
        if (moduleName === 'Leave Request') {
          linkTo = `/transaction/LeaveReqEdit`;
        } else if (moduleName === 'Loan Request') {
          linkTo = `/transaction/LoanReqEdit`;
        }
        const dataToSend = { data: requestID };

        const handleClick = () => {
          navigate(linkTo, { state: dataToSend });
        };

        return (
          <Link to={{ pathname: linkTo, search: `?id=${requestID}` }} style={{ textDecoration: 'none' }} onClick={handleClick}>
            {`${moduleName}/${requestNo}`}
          </Link>
        );
      }
    },
    {
      field: "nos",
      headerName: "No. of Days",
      width: 100,
      renderCell: (params) => {
        const department = params.row.department;
        // const isHighlighted = multipleRequestsDepartments.has(department);

        return (
          <>
            {params.row.leave_request?.request_nos}
          </>
        );
      }
    },
    {
      field: "requestuser_name",
      headerName: "Sender",
      width: 150,
      renderCell: (params) => {
        const department = params.row.department;
        // const isHighlighted = multipleRequestsDepartments.has(department);

        return (
          <>
            {params.row.requestuser_name}
          </>
        );
      }
    },
    {
      field: "employee",
      headerName: "Employee",
      width: 150,
      renderCell: (params) => {
        const department = params.row.department;
        // const isHighlighted = multipleRequestsDepartments.has(department);

        return (
          <>
            {params.row.leave_request?.employee_name?.full_name}
          </>
        );
      }
    },
    {
      field: "department",
      headerName: "Department",
      width: 150,
      renderCell: (params) => {
        const department = params.row.department;
        // const isHighlighted = multipleRequestsDepartments.has(department);

        return (
          <>
            {params.row.leave_request?.employee_name?.department?.discription}
          </>
        );
      }
    },
    {
      field: "request_date",
      headerName: "Request Date",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {convertToIST(params.row.leave_request?.request_date || params.row.request_date)}
          </>
        );
      }
    },
    {
      field: "approved",
      headerName: "Approved",
      width: 150,
      renderCell: (params) => {
        const department = params.row.department;
        // const isHighlighted = multipleRequestsDepartments.has(department);

        return (
          <>
            {params.row.approved}
          </>
        );
      }
    },
    // {
    //   field: "sno",
    //   headerName: "Request No.",
    //   width: 150,
    //   renderCell: (params) => {
    //     const department = params.row.department;
    //     // const isHighlighted = multipleRequestsDepartments.has(department);
    //     const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
    //     const editdata = reqData[rowIndex];
    //     const requestID = params.row.leave_request?._id;
    //     const requestNo = params.row.sno;
    //     const moduleName = params.row.module_name;

    //     let linkTo = '';
    //     if (moduleName === 'Leave Request') {
    //       linkTo = `/transaction/LeaveReqEdit`;
    //     } else if (moduleName === 'Loan Request') {
    //       linkTo = `/transaction/LoanReqEdit`;
    //     }
    //     const dataToSend = { data: requestID };

    //     const handleClick = () => {
    //       navigate(linkTo, { state: dataToSend });
    //     };

    //     return (
    //       <Link to={{ pathname: linkTo, search: `?id=${requestID}` }} style={{ textDecoration: 'none' }} onClick={handleClick}>
    //         {`${moduleName}/${requestNo}`}
    //       </Link>
    //     );
    //   }
    // },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="text"
          color="primary"
          size='small'
          style={{ padding: '0px' }}
          onClick={() => handleEdit(params.row)}
        >
          <EditIcon fontSize="small" />
        </Button>
      )
    }
  ];

  const AteenReqColumns = [
    // { field: "module_name", headerName: "Module", flex:1 },
    { field: "requestuser_name", headerName: "Sender", flex: 1 },
    { field: "employee", headerName: "Employee", width: 150 },
    { field: "department", headerName: "Department", flex: 1 },
    { field: "dalatten_shiftnm", headerName: "Shift", flex: 1 },
    { field: "intime", headerName: "In-Time", flex: 1 },
    { field: "outtime", headerName: "Out-Time", flex: 1 },
    { field: "request_date", headerName: "Date", flex: 1 },
    { field: "approved", headerName: "Approved", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="text"
          color="primary"
          size='small'
          style={{ padding: '0px' }}
          onClick={() => handleEdit(params.row)}
        >
          <EditIcon fontSize="small" />
        </Button>
      )
    }
  ];

  const handleReqClick = (params) => {
    const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
    const editdata = reqData[rowIndex];

    const requestID = editdata?.leave_request?._id;
    const requestNo = editdata?.sno;
    const moduleName = editdata?.module_name;

    let linkTo = '';
    if (moduleName === 'Leave Request') {
      linkTo = `/transaction/LeaveReqEdit`;
    } else if (moduleName === 'Loan Request') {
      linkTo = `/transaction/LoanReqEdit`;
    }
    const dataToSend = { data: requestID };
    navigate(linkTo, { state: dataToSend });
  };

  useEffect(() => {
    user_rights_securityGET();
  }, [])

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  const modalStyle = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', border: '2px solid', boxShadow: 24, padding: 16, minWidth: 300, maxWidth: 500, textAlign: 'center'
  };

  const depcolumns = [
    { field: "department", headerName: "DEPARTMENT" },
    { field: "Present", headerName: "P" },
    { field: "Absent", headerName: "A" },
    { field: "Night", headerName: "N" },
    { field: "Half Day", headerName: "HD" },
    { field: "Paid Holiday", headerName: "PH" },
    { field: "Weekly Off", headerName: "WO" },
    { field: "Not Marked", headerName: "NM" },
    { field: "dalatten_overtime", headerName: "OT" }
  ];

  const shiftcolumns = [
    { field: "shiftName", headerName: "SHIFT" },
    { field: "Present", headerName: "P" },
    { field: "Absent", headerName: "A" },
    { field: "Night", headerName: "N" },
    { field: "Half Day", headerName: "HD" },
    { field: "Paid Holiday", headerName: "PH" },
    { field: "Weekly Off", headerName: "WO" },
    { field: "Not Marked", headerName: "NM" },
    { field: "overtimeTotal", headerName: "OT" }
  ];

  const convertToIndianTime = (utcTimestamp) => {
    const utcDate = dayjs.utc(utcTimestamp);
    if (utcDate.isValid()) {
      return utcDate.tz('Asia/Kolkata').format('DD-MM-YYYY');
    } else {
      return 'N/A';
    }
  };

 

  const NewBirthData = BirthData.map((item, index) => ({
    ...item,
    id: index + 1,
    event_date: convertToIndianTime(item?.record_type === 'Birthday' ? item?.birth_date : item?.joining)
  }));

  const birthcolumns = [
    { field: "id", headerName: "Serial No.", flex: 1 },
    { field: "full_name", headerName: "Employee Name", flex: 1 },
    { field: "event_date", headerName: "Date of Event", flex: 1 },
    { field: "record_type", headerName: "Event Type", flex: 1 }
  ];

  return (
    <Box p={1}>

      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px', gap: 10 }}>

        {/* Employees Button */}
        <Button
          variant="contained"
          size="large"
          // onClick={() => { navigate('/HrMaster/EmpMaster/StaffDetails') }}
          onClick={(e) => {
            if (e.metaKey || e.ctrlKey) {
              const url = `/HrMaster/EmpMaster/StaffDetails`;
              window.open(url, '_blank');
            } else {
              navigate('/HrMaster/EmpMaster/StaffDetails')
            }
          }}
          sx={{
            background: 'linear-gradient(45deg, #4CAF50 30%, #8BC34A 90%)', // Green gradient
            color: 'white',
            borderRadius: '16px',
            padding: '2px 4px',
            // maxWidth: '200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            transition: '0.3s ease-in-out',
            fontSize: '1rem',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            textTransform: 'none',
            '&:hover': {
              background: 'linear-gradient(45deg, #388E3C 50%, #8BC34A 100%)',
              transform: 'scale(1.05)',
              boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          {/* <Diversity1Icon sx={{ fontSize: '1.75rem' }} /> */}
          <Box textAlign="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', opacity: 0.75, fontWeight: 'bold' }}>
              Employees
            </Typography>
            <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: 1.2 }}>
              {CountData?.empCount}
            </Typography>

          </Box>
        </Button>

        {/* OT Hours and Amount Button */}
        <Button
          variant="contained"
          size="large"
          // onClick={() => { navigate('/reports/ot_report'); }}
          onClick={(e) => {
            if (e.metaKey || e.ctrlKey) {
              const url = `/reports/ot_report`;
              window.open(url, '_blank');
            } else {
              navigate('/reports/ot_report')
            }
          }}
          sx={{
            background: 'linear-gradient(45deg, #003366 30%, #006699 90%)', // Dark blue gradient
            color: 'white',
            borderRadius: '16px',
            padding: '2px 4px',
            // maxWidth: '200px',
            width: '100%', // Adjusted for combined content
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'space-between', // Use space-between for balanced layout
            transition: '0.3s ease-in-out',
            fontSize: '1rem',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            textTransform: 'none',
            '&:hover': {
              background: 'linear-gradient(45deg, #002244 50%, #004080 100%)', // Darker blue gradient on hover
              transform: 'scale(1.05)',
              boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <Box display="flex" alignItems="center" gap="12px">
            {/* <Diversity1Icon sx={{ fontSize: '1.75rem' }} /> */}
            <Box textAlign="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', opacity: 0.75, fontWeight: 'bold' }}>
                OT
              </Typography>
              <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: 1.2 }}>
                {CountData?.globalTotalOtHours} Hours ₹{CountData?.globalTotalOtAmount}
              </Typography>

            </Box>
          </Box>
        </Button>

        {/* Late Comings Button */}
        <Button
          variant="contained"
          size="large"
          // onClick={() => { navigate('/reports/latecoming'); }}
          onClick={(e) => {
            if (e.metaKey || e.ctrlKey) {
              const url = `/reports/latecoming`;
              window.open(url, '_blank');
            } else {
              navigate('/reports/latecoming')
            }
          }}
          sx={{
            background: 'linear-gradient(45deg, #FF9800 30%, #FFC107 90%)', // Orange gradient
            color: 'white',
            borderRadius: '16px',
            padding: '2px 4px',
            // maxWidth: '200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            transition: '0.3s ease-in-out',
            fontSize: '1rem',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            textTransform: 'none',
            '&:hover': {
              background: 'linear-gradient(45deg, #F57C00 50%, #FFC107 100%)',
              transform: 'scale(1.05)',
              boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          {/* <Diversity1Icon sx={{ fontSize: '1.75rem' }} /> */}
          <Box textAlign="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', opacity: 0.75, fontWeight: 'bold' }}>
              Late
            </Typography>
            <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: 1.2 }}>
              {CountData?.lateComingCount}
            </Typography>

          </Box>
        </Button>

        {/* Attendance Requests Button */}
        <Button
          variant="contained"
          size="large"
          onClick={() => setAttendanceReqdrawerOpen(!AttendanceReqdrawerOpen)}
          sx={{
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)', // Blue gradient
            color: 'white',
            borderRadius: '16px',
            padding: '2px 4px',
            // maxWidth: '200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            transition: '0.3s ease-in-out',
            fontSize: '1rem',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            textTransform: 'none',
            '&:hover': {
              background: 'linear-gradient(45deg, #1976D2 50%, #21CBF3 100%)',
              transform: 'scale(1.05)',
              boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          {/* <Diversity1Icon sx={{ fontSize: '1.75rem' }} /> */}
          <Box textAlign="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', opacity: 0.75, fontWeight: 'bold' }}>
              Atd Requlizar
            </Typography>
            <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: 1.2 }}>
              {CountData?.attendanceCount}
            </Typography>
          </Box>
        </Button>

        {/* Leave Requests Button */}
        <Button
          variant="contained"
          size="large"
          onClick={() => setDrawerOpen(!drawerOpen)}
          sx={{
            background: 'linear-gradient(45deg, #E91E63 30%, #F50057 90%)', // Pink gradient
            color: 'white',
            borderRadius: '16px',
            padding: '2px 4px',
            // maxWidth: '200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            transition: '0.3s ease-in-out',
            fontSize: '1rem',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            textTransform: 'none',
            '&:hover': {
              background: 'linear-gradient(45deg, #D81B60 50%, #F50057 100%)',
              transform: 'scale(1.05)',
              boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          {/* <Diversity1Icon sx={{ fontSize: '1.75rem' }} /> */}
          <Box textAlign="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', opacity: 0.75, fontWeight: 'bold' }}>
              Leave Requests
            </Typography>
            <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: 1.2 }}>
              {CountData?.leaveCount}
            </Typography>

          </Box>
        </Button>

        {/* Loan Requests Button */}
        <Button
          variant="contained"
          size="large"
          onClick={() => setLoandrawerOpen(!LoandrawerOpen)}
          sx={{
            background: 'linear-gradient(45deg, #00897B 30%, #26ab9d 90%)',
            color: 'white',
            borderRadius: '16px',
            padding: '2px 4px',
            // maxWidth: '200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            transition: '0.3s ease-in-out',
            fontSize: '1rem',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            textTransform: 'none',
            '&:hover': {
              background: 'linear-gradient(45deg, #00897B 50%, #26ab9d 100%)', // Darker teal gradient on hover
              transform: 'scale(1.05)',
              boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          {/* <Diversity1Icon sx={{ fontSize: '1.75rem' }} /> */}
          <Box textAlign="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', opacity: 0.75, fontWeight: 'bold' }}>
              Loan Requests
            </Typography>
            <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: 1.2 }}>
              {CountData?.loanCount}
            </Typography>

          </Box>
        </Button>

        {/* Attrition Rate */}
        <Button
          variant="contained"
          size="large"
          // onClick={() => { navigate('/reports/latecoming'); }}
          onClick={(e) => {
            if (e.metaKey || e.ctrlKey) {
              const url = `/reports/joining_report`;
              window.open(url, '_blank');
            } else {
              navigate('/reports/joining_report')
            }
          }}
          sx={{
            background: 'linear-gradient(45deg, #5d68fc 30%, #ff9a8b 90%)', // Blue and Peach
            color: 'white',
            borderRadius: '16px',
            padding: '2px 4px',
            // maxWidth: '200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            transition: '0.3s ease-in-out',
            fontSize: '1rem',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            textTransform: 'none',
            '&:hover': {
              background: 'linear-gradient(45deg, #4c59d1 50%, #ff7f6d 100%)',
              transform: 'scale(1.05)',
              boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          {/* <Diversity1Icon sx={{ fontSize: '1.75rem' }} /> */}
          <Box textAlign="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', opacity: 0.75, fontWeight: 'bold' }}>
              Attrition Rate
            </Typography>
            <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: 1.2 }}>
              {CountData?.attritionRate}
            </Typography>

          </Box>
        </Button>

        {/* Short*/}
        <Button
          variant="contained"
          size="large"
          // onClick={() => { navigate('/reports/latecoming'); }}
          onClick={(e) => {
            if (e.metaKey || e.ctrlKey) {
              const url = `/reports/MachineWiseRequirement`;
              window.open(url, '_blank');
            } else {
              navigate('/reports/MachineWiseRequirement')
            }
          }}
          sx={{
            background: 'linear-gradient(45deg, #d1458b 30%, #803259 90%)', // Pink and Teal
            color: 'white',
            borderRadius: '16px',
            padding: '2px 4px',
            // maxWidth: '200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            transition: '0.3s ease-in-out',
            fontSize: '1rem',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            textTransform: 'none',
            '&:hover': {
              background: 'linear-gradient(45deg, #b13576 50%, #803259 100%)',
              transform: 'scale(1.05)',
              boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          {/* <Diversity1Icon sx={{ fontSize: '1.75rem' }} /> */}
          <Box textAlign="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', opacity: 0.75, fontWeight: 'bold' }}>
              Shortage
            </Typography>
            <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: 1.2 }}>
              {CountData?.shortCount}
            </Typography>

          </Box>
        </Button>

        {/* Birthdays */}
        <Button
          variant="contained"
          size="large"
          onClick={() => setBirthdrawerOpen(!BirthdrawerOpen)}
          sx={{
            background: 'linear-gradient(45deg, #00897B 30%, #26ab9d 90%)',
            color: 'white',
            borderRadius: '16px',
            padding: '2px 4px',
            // maxWidth: '200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            transition: '0.3s ease-in-out',
            fontSize: '1rem',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            textTransform: 'none',
            '&:hover': {
              background: 'linear-gradient(45deg, #00897B 50%, #26ab9d 100%)', // Darker teal gradient on hover
              transform: 'scale(1.05)',
              boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          {/* <Diversity1Icon sx={{ fontSize: '1.75rem' }} /> */}
          <Box textAlign="center">
          <Typography variant="caption" sx={{ fontSize: '0.875rem', opacity: 0.75, fontWeight: 'bold' }}>
              Celebrations
            </Typography>
            <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: 1.2 }}>
              {CountData?.BirthempCount}
            </Typography>

          </Box>
        </Button>

        {/* Task Button */}
        <Button
          variant="contained"
          size="large"
          onClick={() => setTaskdrawerOpen(!TaskdrawerOpen)}
          sx={{
            background: 'linear-gradient(45deg, #FF5722 30%, #FF7043 90%)', // Red gradient

            color: 'white',
            borderRadius: '16px',
            padding: '2px 4px',
            // maxWidth: '200px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
            transition: '0.3s ease-in-out',
            fontSize: '1rem',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            textTransform: 'none',
            '&:hover': {
              background: 'linear-gradient(45deg, #E64A19 50%, #FF7043 100%)',
              transform: 'scale(1.05)',
              boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          {/* <Diversity1Icon sx={{ fontSize: '1.75rem' }} /> */}
          <Box textAlign="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', opacity: 0.75, fontWeight: 'bold' }}>
              Tasks
            </Typography>
            <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1rem', lineHeight: 1.2 }}>
              {CountData?.TaskCount}
            </Typography>
          </Box>
        </Button>

      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '0px', gap: 10 }}>
        {/* Task Button */}
        {/* <Button
          variant="contained"
          size="small"
          onClick={() => setTaskdrawerOpen(!TaskdrawerOpen)}
          sx={{
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)', // Blue gradient
            color: 'white',
            borderRadius: '8px',
            height: '32px', // Set a specific height
            padding: '2px 4px', // Further reduced padding
            transition: '0.3s',
            fontSize: '0.75rem', // Reduced font size for a smaller appearance
            '&:hover': {
              background: 'linear-gradient(45deg, #2196F3 50%, #21CBF3 100%)',
              transform: 'scale(1.05)',
            },
          }}
        >
          Tasks
        </Button> */}

        {/* Shift Button */}
        {/* <Button
          variant="contained"
          size="small"
          onClick={() => setShiftdrawerOpen(!ShiftdrawerOpen)}
          sx={{
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)', // Blue gradient
            color: 'white',
            borderRadius: '8px',
            height: '32px', // Set a specific height
            padding: '2px 4px', // Further reduced padding
            transition: '0.3s',
            fontSize: '0.75rem', // Reduced font size for a smaller appearance
            '&:hover': {
              background: 'linear-gradient(45deg, #2196F3 50%, #21CBF3 100%)',
              transform: 'scale(1.05)',
            },
          }}
        >
          Shift Attendance
        </Button> */}

        {/* Dep Button */}
        {/* <Button
          variant="contained"
          size="small"
          onClick={() => setDepdrawerOpen(!DepdrawerOpen)}
          sx={{
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)', // Blue gradient
            color: 'white',
            borderRadius: '8px',
            height: '32px', // Set a specific height
            padding: '2px 4px', // Further reduced padding
            transition: '0.3s',
            fontSize: '0.75rem', // Reduced font size for a smaller appearance
            '&:hover': {
              background: 'linear-gradient(45deg, #2196F3 50%, #21CBF3 100%)',
              transform: 'scale(1.05)',
            },
          }}
        >
          Department Attendance
        </Button> */}

        {/* Loan/Leave Button */}
        {/* <Button
          variant="contained"
          size="small"
          onClick={() => setDrawerOpen(!drawerOpen)}
          sx={{
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)', // Blue gradient
            color: 'white',
            borderRadius: '8px',
            height: '32px', // Set a specific height
            padding: '2px 4px', // Further reduced padding
            transition: '0.3s',
            fontSize: '0.75rem', // Reduced font size for a smaller appearance
            '&:hover': {
              background: 'linear-gradient(45deg, #2196F3 50%, #21CBF3 100%)',
              transform: 'scale(1.05)',
            },
          }}
        >
          Loan/Leave Requests
        </Button> */}



      </div>
      {TaskdrawerOpen && (
        <Paper sx={{ border: '1px solid #ccc', position: 'relative' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold', padding: '10px' }}>
              Tasks List
            </Typography>
          </div>
          {TaskdrawerOpen && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ background: 'linear-gradient(#FFFFFF, #60a5f2)' }}>
                  <TableRow>
                    <TableCell align="center">Task Plan</TableCell>
                    <TableCell align="center">Task Description</TableCell>
                    <TableCell align="center">Date Time Allotted</TableCell>
                    <TableCell align="center">Images</TableCell>
                    <TableCell align="center">Priority</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Update Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Taskdata?.length > 0 ? (
                    Taskdata.map((row, index) => (
                      <React.Fragment key={index}>
                        <TableRow >
                          <TableCell align="center">{row?._id?.task_name}</TableCell>
                          <TableCell align="center">{row?._id?.task_description}</TableCell>
                          <TableCell align="center">{`${convertAndFormatDate(row?._id?.startdate)} - ${convertAndFormatDate(row?._id?.duedate)}`}</TableCell>
                          <TableCell align="center" >
                            {row?._id?.filenames?.map((imageName, imageIndex) => (
                              <img key={imageIndex} src={`${process.env.REACT_APP_API_URL}/uploads/${imageName}`} alt="" style={{ width: '50px', height: '30px', padding: '2px' }} onClick={() => handleImageClick(row?._id?.filenames, imageIndex)} />
                            ))}
                          </TableCell>
                          <TableCell align="center">{row?._id?.task_priority}</TableCell>
                          <TableCell align="center">{row?._id?.task_status}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="text"
                              color="primary"
                              size='small'
                              sx={{ padding: '0px' }}
                              onClick={() => handleTaskStatus(Taskdata[index])}
                            >
                              <EditIcon fontSize="small" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  ) :
                    <TableRow>
                      <TableCell colSpan={7} align="center" style={{ fontSize: '16px', padding: '8px' }}>
                        No Tasks
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      )}

      {/* {AttendreqData?.length > 0 && ( */}
      {AttendanceReqdrawerOpen && (
        <Paper sx={{ border: '1px solid #ccc', }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold', padding: '10px' }}>
              Attendance Regularize Requests
            </Typography>
          </div>
          {AttendanceReqdrawerOpen && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} style={{ overflow: 'hidden' }}>
                {/* <MUIDataTable title={<span style={{ fontWeight: 'bold', fontSize: '23px' }}>Requests</span>} data={NewreqData} columns={columns} options={options} /> */}
                <DataGrid
                  columns={AteenReqColumns}
                  rows={AttenNewreqData}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10, 20]}
                  checkboxSelection={false}
                  disableRowSelectionOnClick={true}
                  density="compact"
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      )}

      {/* {reqData?.length > 0 && ( */}
      {drawerOpen && (
        <Paper sx={{ border: '1px solid #ccc', }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold', padding: '10px' }}>
              Leave Requests
            </Typography>
          </div>
          {drawerOpen && (

            // <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} style={{ overflow: 'hidden' }}>
                {/* <MUIDataTable title={<span style={{ fontWeight: 'bold', fontSize: '23px' }}>Requests</span>} data={NewreqData} columns={columns} options={options} /> */}
                <DataGrid
                  columns={columns}
                  rows={NewreqData}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10, 20]}
                  checkboxSelection={false}
                  disableRowSelectionOnClick={true}
                  getRowClassName={(params) =>
                    overlappingDepartments.has(params.row.department) ? 'highlighted-row' : ''
                  }
                  density="compact"
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  sx={{
                    '& .MuiDataGrid-cell': {
                      padding: 0, // Remove padding
                      margin: 0, // Remove margin
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      borderBottom: 'none', // Remove bottom border of column headers
                    },
                    '& .MuiDataGrid-row': {
                      margin: 0, // Remove margin between rows
                    },
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none', // Remove focus outline
                    },
                  }}
                />
              </Grid>
            </Grid>
            // </Drawer>
          )}
        </Paper>
      )}

      {/* {Loan?.length > 0 && ( */}
      {LoandrawerOpen && (
        <Paper sx={{ border: '1px solid #ccc', }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold', padding: '10px' }}>
              Loan Requests
            </Typography>
          </div>
          {LoandrawerOpen && (

            // <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} style={{ overflow: 'hidden' }}>
                {/* <MUIDataTable title={<span style={{ fontWeight: 'bold', fontSize: '23px' }}>Requests</span>} data={NewreqData} columns={columns} options={options} /> */}
                <DataGrid
                  columns={columns}
                  rows={NewreqLoanData}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10, 20]}
                  checkboxSelection={false}
                  disableRowSelectionOnClick={true}
                  getRowClassName={(params) =>
                    overlappingDepartments.has(params.row.department) ? 'highlighted-row' : ''
                  }
                  density="compact"
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  sx={{
                    '& .MuiDataGrid-cell': {
                      padding: 0, // Remove padding
                      margin: 0, // Remove margin
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      borderBottom: 'none', // Remove bottom border of column headers
                    },
                    '& .MuiDataGrid-row': {
                      margin: 0, // Remove margin between rows
                    },
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none', // Remove focus outline
                    },
                  }}
                />
              </Grid>
            </Grid>
            // </Drawer>
          )}
        </Paper>
      )}

      {/* {Loan?.length > 0 && ( */}
      {BirthdrawerOpen && (
        <Paper sx={{ border: '1px solid #ccc', }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold', padding: '10px' }}>
              Celebrations
            </Typography>
          </div>
          {BirthdrawerOpen && (

            // <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} style={{ overflow: 'hidden' }}>
                {/* <MUIDataTable title={<span style={{ fontWeight: 'bold', fontSize: '23px' }}>Requests</span>} data={NewreqData} columns={columns} options={options} /> */}
                <DataGrid
                  columns={birthcolumns}
                  rows={NewBirthData}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10, 20]}
                  checkboxSelection={false}
                  disableRowSelectionOnClick={true}
                  density="compact"
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </Grid>
            </Grid>
            // </Drawer>
          )}
        </Paper>
      )}

      {DepdrawerOpen && (
        <Paper sx={{ border: '1px solid #ccc', position: 'relative' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold', padding: '10px' }}>
              Department Attendance
            </Typography>

            {/* Date */}
            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', padding: '10px' }}>
              <TextField
                type="text"
                size="small"
                label="Select Range"
                value={`${formatDates(selectionRange.startDate)} - ${formatDates(selectionRange.endDate)}`}
                onFocus={toggleDateRangePicker}
                readOnly
                fullWidth
                InputProps={{
                  disableUnderline: true, // Remove default underline
                  style: { cursor: 'pointer', textAlign: 'center' } // Center-align text and change cursor to pointer when hovering
                }}
                InputLabelProps={{
                  shrink: true, // Ensure the label doesn't float above the input when value is present
                }}
                onClick={toggleDateRangePicker} // Open DateRangePicker on click
              />
              {isOpen && (
                <div style={{ position: 'absolute', zIndex: 999, marginRight: '400px' }} ref={dateRangePickerRef}>
                  <DateRangePicker
                    ranges={[{ startDate: tempStartDate, endDate: tempEndDate, key: 'selection' }]}
                    onChange={handleSelect}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={applyDateRange}
                    fullWidth
                    style={{ marginTop: '10px' }}
                  >
                    Apply
                  </Button>
                </div>
              )}

            </div>
          </div>

          {DepdrawerOpen && (
            <Paper sx={{ border: '1px solid #ccc', position: 'relative' }}>
              {DepdrawerOpen && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}> {/* Chart on the right side */}
                    <Paper sx={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Line data={DepchartData} options={Depoptions} />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}> {/* Table on the left side */}
                    <Paper sx={{ height: '300px', display: 'flex', flexDirection: 'column' }}>
                      <TableContainer sx={{ flex: 1, overflow: 'auto' }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {depcolumns.map((column) => (
                                <TableCell key={column.field} sx={{ fontWeight: 'bold', padding: '4px' }}>
                                  {column.headerName}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {depdata
                              .slice(deppage * deprowsPerPage, deppage * deprowsPerPage + deprowsPerPage)
                              .map((row) => (
                                <TableRow key={row.id}>
                                  {depcolumns.map((column) => (
                                    <TableCell key={column.field} sx={{ padding: '4px', fontSize: '0.8rem' }}>
                                      {row[column.field]}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={depdata.length}
                        rowsPerPage={deprowsPerPage}
                        page={deppage}
                        onPageChange={dephandleChangePage}
                        onRowsPerPageChange={dephandleChangeRowsPerPage}
                        size="small"
                      />
                    </Paper>
                  </Grid>

                </Grid>

              )}
            </Paper>
          )}
        </Paper>
      )}

      {/* {Shift?.length > 0 && ( */}
      {ShiftdrawerOpen && (
        <Paper sx={{ border: '1px solid #ccc', position: 'relative' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold', padding: '10px' }}>
              Shift Attendance
            </Typography>

            {/* Date */}
            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', padding: '10px' }}>
              <TextField
                type="text"
                size="small"
                label="Select Range"
                value={`${formatDates(SHIFTselectionRange.startDate)} - ${formatDates(SHIFTselectionRange.endDate)}`}
                onFocus={SHIFTtoggleDateRangePicker}
                readOnly
                fullWidth
                InputProps={{
                  disableUnderline: true, // Remove default underline
                  style: { cursor: 'pointer', textAlign: 'center' } // Center-align text and change cursor to pointer when hovering
                }}
                InputLabelProps={{
                  shrink: true, // Ensure the label doesn't float above the input when value is present
                }}
                onClick={SHIFTtoggleDateRangePicker} // Open DateRangePicker on click
              />
              {SHIFTisOpen && (
                <div style={{ position: 'absolute', zIndex: 999, marginRight: '400px' }} ref={SHIFTdateRangePickerRef}>
                  <DateRangePicker
                    ranges={[{ startDate: SHIFTtempStartDate, endDate: SHIFTtempEndDate, key: 'selection' }]}
                    onChange={SHIFThandleSelect}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={SHIFTapplyDateRange}
                    fullWidth
                    style={{ marginTop: '10px' }}
                  >
                    Apply
                  </Button>
                </div>
              )}

            </div>
          </div>
          {ShiftdrawerOpen && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}> {/* Table on the left side */}
                <Paper sx={{ height: '300px', display: 'flex', flexDirection: 'column' }}>
                  <TableContainer sx={{ flex: 1, overflow: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {shiftcolumns.map((column) => (
                            <TableCell key={column.field} sx={{ fontWeight: 'bold', padding: '4px' }}>
                              {column.headerName}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {shiftdata
                          .slice(shiftpage * shiftrowsPerPage, shiftpage * shiftrowsPerPage + shiftrowsPerPage)
                          .map((row) => (
                            <TableRow key={row.id}>
                              {shiftcolumns.map((column) => (
                                <TableCell key={column.field} sx={{ padding: '4px', fontSize: '0.8rem' }}>
                                  {row[column.field] || 0}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={shiftdata.length}
                    rowsPerPage={shiftrowsPerPage}
                    page={shiftpage}
                    onPageChange={shifthandleChangePage}
                    onRowsPerPageChange={shifthandleChangeRowsPerPage}
                    size="small"
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}> {/* Chart on the right side */}
                <Paper sx={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Bar data={chartData} options={Shiftoptions} />
                </Paper>
              </Grid>
            </Grid>

          )}
        </Paper>
      )}

      {/* {Taskdata?.length > 0 && ( */}

      {(userRights?.includes("Dash Attendance -V") || administrator) ? (
        <Paper sx={{ border: '1px solid #ccc', padding: '10px' }}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={7} style={{ overflow: 'hidden' }}>
              <Paper
                elevation={3}
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  // marginTop: '10px',
                  // minHeight: '180px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'bold', fontSize: '14px' }}
                  >
                    {month} Attendance
                  </Typography>
                  {(userRights?.includes("Detailed Attendance View-V") || administrator) && (
                    <Button variant="contained"
                      // onClick={() => navigate('/DetailedAttendance')} 
                      onClick={(e) => {
                        if (e.metaKey || e.ctrlKey) {
                          const url = `/DetailedAttendance`;
                          window.open(url, '_blank');
                        } else {
                          navigate('/DetailedAttendance')
                        }
                      }}
                      sx={{
                        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)', // Blue gradient
                        color: 'white',
                        borderRadius: '8px',
                        height: '26px', // Set a specific height
                        // padding: '2px 4px', // Further reduced padding
                        transition: '0.3s',
                        fontSize: '0.75rem', // Reduced font size for a smaller appearance
                        '&:hover': {
                          background: 'linear-gradient(45deg, #2196F3 50%, #21CBF3 100%)',
                          transform: 'scale(1.05)',
                        },
                      }}>
                      Detailed Attendance View
                    </Button>
                  )}
                </div>

                <Divider sx={{ marginY: '10px' }} />
                {Graphloading ? (
                  <Box
                    display="flex"
                    padding="10px"
                    marginTop="0px"
                    alignItems="center"
                    justifyContent="center"
                    height="250px" // Adjusted height for loading spinner
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      padding: '0px',
                      marginTop: '0px',
                      height: '300px', // Reduced height of the graph
                    }}
                  >
                    <BarChart
                      dataset={dataset}
                      xAxis={[{ scaleType: 'band', dataKey: 'date' }]}
                      series={[
                        {
                          dataKey: 'present',
                          label: 'Present',
                          valueFormatter,
                          color: '#83c9ab',
                        },
                        {
                          dataKey: 'absent',
                          label: 'Absent',
                          valueFormatter,
                          color: '#cc6e76',
                        },
                      ]}
                      sx={{ width: '100%', height: '100%' }} // Ensure chart scales properly
                      {...chartSetting}
                    />
                  </Box>
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} md={5} style={{ overflow: 'hidden' }}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: '10px',
                      border: '1px solid #ccc',
                      background: 'linear-gradient(135deg, #FFB74D 30%, #FF9800 90%)',
                      // marginTop: '10px',
                      // minHeight: '180px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 'bold', fontSize: '14px' }}
                      >
                        {selectedDate.format('DD MMMM')} Attendance
                      </Typography>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <ArrowBackIosIcon
                          className="arrow"
                          onClick={handlePreviousDate}
                          sx={{ cursor: 'pointer', marginRight: '5px', fontSize: '16px' }}
                        />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <Box width="120px">
                            <DatePicker
                              value={selectedDate}
                              format="DD/MM/YY"
                              sx={{ '& input': { fontSize: '12px', padding: '4px' } }}
                              onChange={handleDateChange}
                              renderInput={(params) => (
                                <TextField {...params} size="small" />
                              )}
                            />
                          </Box>
                        </LocalizationProvider>
                        <ArrowForwardIosIcon
                          className="arrow"
                          onClick={handleNextDate}
                          sx={{ cursor: 'pointer', marginLeft: '5px', fontSize: '16px' }}
                        />
                      </Box>
                    </div>
                    <Divider sx={{ marginY: '10px' }} />
                    {loading ? (
                      <Box display="flex" alignItems="center" justifyContent="center" height="150px">
                        <CircularProgress size={20} />
                      </Box>
                    ) : TodaySummary?.length === 0 ? (
                      <Box display="flex" alignItems="center" justifyContent="center" height="100px">
                        <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>No data available</Typography>
                      </Box>
                    ) : (
                      <Grid container spacing={0}>
                        {["Weekly Off", "Not Marked", "Night", "Present", "Absent", "Unpaid Leave"].map((label) => (
                          <Grid item xs={4} key={label}>
                            <Typography variant="caption" sx={{ fontSize: '12px' }}>{label}</Typography>
                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                              {TodaySummary.find(item => item._id.lvname[0] === label)?.count || 0}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: '10px',
                      border: '1px solid #ccc',
                      background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)', // Blue gradient
                      // marginTop: '10px',
                      // minHeight: '180px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 'bold', fontSize: '14px' }}
                      >
                        Monthly Attendance
                      </Typography>
                      <Select
                        value={month}
                        onChange={handleMonthChange}
                        variant="outlined"
                        sx={{ minWidth: 100, height: 30, fontSize: '12px' }}
                      >
                        {availableMonths.map((month, index) => (
                          <MenuItem key={index} value={month} sx={{ fontSize: '12px' }}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <Divider sx={{ marginY: '10px' }} />
                    {loading ? (
                      <Box display="flex" alignItems="center" justifyContent="center" height="150px">
                        <CircularProgress size={20} />
                      </Box>
                    ) : monthSummary?.length === 0 ? (
                      <Box display="flex" alignItems="center" justifyContent="center" height="100px">
                        <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>No data available</Typography>
                      </Box>
                    ) : (
                      <Grid container spacing={0}>
                        {["Weekly Off", "Not Marked", "Night", "Present", "Absent", "Unpaid Leave"].map((label) => (
                          <Grid item xs={4} key={label}>
                            <Typography variant="caption" sx={{ fontSize: '12px' }}>{label}</Typography>
                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                              {monthSummary.find(item => item._id.lvname[0] === label)?.count || 0}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : ''}

      <Dialog open={openEditModal} onClose={handleCloseEditModal} fullWidth maxWidth="xs">
        <DialogTitle sx={{ fontWeight: 'bold' }}>Update Status</DialogTitle>
        <DialogContent>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={status}
              onChange={(e) => { setStatus(e.target.value) }}
              name="radio-buttons-group"
            >
              <FormControlLabel value="Approve" control={<Radio />} label="Approve" />
              <FormControlLabel value="Revert" control={<Radio />} label="Revert" />
              <FormControlLabel value="Forward" control={<Radio />} label="Forward" />
            </RadioGroup>
          </FormControl>
          {status === "Forward" && (
            <FormControl fullWidth>
              <Autocomplete
                value={selectedUser}
                onChange={(event, newValue) => {
                  setSelectedUser(newValue);
                }}
                options={userList}
                getOptionLabel={(option) => option?.usrnm || ""}
                getOptionSelected={(option, value) => option._id === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Users"
                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                    sx={{ "& .MuiInputBase-root": { height: '56px' } }}
                  />
                )}
              />
            </FormControl>
          )}
          <TextField
            id="remark"
            label="Remark"
            value={remark}
            onChange={(e) => { setRemark(e.target.value) }}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal} color="primary">Cancel</Button>
          <Button color="primary" disabled={!status || (status === 'Forward' && !selectedUser)} onClick={() => { handlesave() }}>Save</Button>
        </DialogActions>
      </Dialog>

      <div style={{ position: 'fixed', top: '100px', left: 0, right: 0, zIndex: '999', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        {showSlider && (
          <>
            {sliderImages.length === 1 ? (
              <div style={{ textAlign: "center" }}>
                <button onClick={handleCloseSlider}>Close</button>
                <img
                  src={sliderImages[0]}
                  alt=""
                  style={{ width: "100%", maxWidth: "500px", height: "50vh" }}
                />
              </div>
            ) : (
              <div style={{ maxWidth: "500px", margin: "0 auto" }}>
                <button onClick={handleCloseSlider}>Close</button>
                <Slider {...settings}>
                  {sliderImages.map((item, index) => (
                    <div key={index}>
                      <img
                        src={item}
                        alt=""
                        style={{ width: "100%", height: "50vh" }}
                      />
                    </div>
                  ))}
                </Slider>
                <div style={{ textAlign: "center" }}>
                  {sliderImages.map((_, index) => (
                    <span
                      key={index}
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: currentIndex === index ? "#000" : "#ccc",
                        margin: "0 5px",
                        cursor: "pointer"
                      }}
                      onClick={() => setCurrentIndex(index)}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <Modal
        open={successModalOpen}
        onClose={handleSuccessModalClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#007bff' }}>
          <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
          <p id="success-modal-description">{ActionMessage}</p>
          <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
        </div>
      </Modal>

      <Modal
        open={errorModalOpen}
        onClose={handleErrorModalClose}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
          <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
          <p id="error-modal-description">{ActionMessage}.</p>
          <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
        </div>
      </Modal>

      <Dialog open={openTaskStatusModal} onClose={handleCloseTaskStatusModal} fullWidth maxWidth="xs">
        <DialogTitle sx={{ fontWeight: 'bold' }}>Update Status</DialogTitle>
        <DialogContent>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={TaskStatus}
              onChange={(e) => { setTaskStatus(e.target.value) }}
              name="radio-buttons-group"
            >
              <FormControlLabel value="Working On It" control={<Radio />} label="Working On It" />
              <FormControlLabel value="Stuck" control={<Radio />} label="Stuck" />
              <FormControlLabel value="Done" control={<Radio />} label="Done" />
            </RadioGroup>
          </FormControl>
          {/* {status === "Forward" && (
            <FormControl fullWidth>
              <Autocomplete
                value={selectedUser}
                onChange={(event, newValue) => {
                  setSelectedUser(newValue);
                }}
                options={userList}
                getOptionLabel={(option) => option?.usrnm || ""}
                getOptionSelected={(option, value) => option._id === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Users"
                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                    sx={{ "& .MuiInputBase-root": { height: '56px' } }}
                  />
                )}
              />
            </FormControl>
          )} */}
          <TextField
            id="remark"
            label="Remarks"
            value={TaskRemark}
            onChange={(e) => { setTaskRemark(e.target.value) }}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTaskStatusModal} color="primary">Cancel</Button>
          <Button color="primary" disabled={!TaskStatus} onClick={() => { handleTaskStatusSave() }}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Home;
