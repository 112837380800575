import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, Autocomplete, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { Button, Grid, Paper, Select, MenuItem } from '@mui/material';
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';

const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function BankSalaryList() {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [BankNames, setBankNames] = useState([]);
    const [Bank, setBank] = useState();
    const [maindata, setmainData] = useState([]);
    const [DivValues, setDivValues] = useState([]);

    const [Month, setMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [NewCheque, setNewCheque] = useState('');

    useEffect(() => {
        fetchAdvanceData();
    }, [Month]);

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewCheque('');
    };

    const BankGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/employeegrade_master/BankGET`,
                {
                    params: {
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString)
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            console.log('API Response:', response.data.lastEntryNo);
            setBankNames(response.data.lastEntryNo)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        BankGET(); // Call fetchData when component mounts or when searchText, rowsPerPage, or currentPage changes
        // }, [searchText, rowsPerPage, currentPage]);
    }, []);

    const fetchAdvanceData = async () => {
        setLoading(true);
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            setData([]);
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salart_handleRouter/Salary_ListReports?search=${search}`,
                {
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                    Month: Month
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            const dfdf = await extractEmployeeData(generateArrays(response.data.data))
            setData(dfdf);
            setmainData(dfdf);


        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const generateArrays = (data) => {
        return data.map(item => {
            const earnings = item.salryhd_or_group.filter(head => head.salryhd_sign === "(+)");
            const deductions = item.salryhd_or_group.filter(head => head.salryhd_sign === "(-)");
            const eArray = item.salryhd_or_group.filter(head => head.salryhd_sign === "E");

            return {
                ...item,
                earnings,
                deductions,
                eArray
            };
        });
    };

    useEffect(() => {
        console.log(Bank, 'Bank');
        if (Bank) {
            const filteredArray = maindata.filter(item => {
                return Number(item?.net_salary) > 0 && item?.bank === Bank?.discription && item?.salary_mode === 'BANK'; // Ensure to return the boolean result
            });
            setData(filteredArray);

            setData(filteredArray);
        } else {
            const filteredArray = maindata.filter(item => {
                console.log('item', item)
                return (Number(item?.net_salary) > 0 && item?.salary_mode === 'BANK' && (Number(item?.bankNo))); // Ensure to return the boolean result
            });

            setData(filteredArray); // Reset to the original data if salMode is not 'CASH' or 'BANK'
        }
    }, [Bank, maindata]); // Make sure to include maindata as a dependency

    // const columns = [
    //     { field: 'bank', headerName: 'Bank', flex: 1 },
    //     { field: 'bankHold', headerName: 'Account Name', flex: 1 },
    //     { field: 'bankNo', headerName: 'Account Number', flex: 1 },
    //     { field: 'bankBranch', headerName: 'Bank Branch', flex: 1 },
    //     { field: 'ifsc_code', headerName: 'IFSC Code', flex: 1 },
    //     { field: 'net_salary', headerName: 'Narration', flex: 1 },
    //     { field: 'month', headerName: 'Month', flex: 1 },
    // ];
    const [columns, setColumns] = useState([
        { field: 'bank', headerName: 'Bank', flex: 1 },
        { field: 'bankHold', headerName: 'Account Name', flex: 1 },
        { field: 'bankNo', headerName: 'Account Number', flex: 1 },
        { field: 'bankBranch', headerName: 'Bank Branch', flex: 1 },
        { field: 'ifsc_code', headerName: 'IFSC Code', flex: 1 },
        { field: 'net_salary', headerName: 'Amount', flex: 1 },
        { field: 'Month', headerName: 'Month', flex: 1 },
    ]);

    useEffect(() => {
        if (Bank?.order && Bank?.order?.length > 0) {
            const columnMap = {
                "Bank": 'bank',
                "Account Name": 'bankHold',
                "Account Number": 'bankNo',
                "Bank Branch": 'bankBranch',
                "IFSC Code": 'ifsc_code',
                "Amount": 'net_salary',
                "Month": 'Month',
            };

            // Filter and map columns strictly based on Bank.order
            const orderedColumns = Bank.order
                .map(orderItem => {
                    const field = columnMap[orderItem];
                    return columns.find(col => col.field === field); // Find the matching column
                })
                .filter(Boolean); // Remove undefined columns if any order items do not match

            setColumns(orderedColumns); // Set only the matched columns in the order
        }
        else {
            setColumns([
                { field: 'bank', headerName: 'Bank', flex: 1 },
                { field: 'bankHold', headerName: 'Account Name', flex: 1 },
                { field: 'bankNo', headerName: 'Account Number', flex: 1 },
                { field: 'bankBranch', headerName: 'Bank Branch', flex: 1 },
                { field: 'ifsc_code', headerName: 'IFSC Code', flex: 1 },
                { field: 'net_salary', headerName: 'Amount', flex: 1 },
                { field: 'Month', headerName: 'Month', flex: 1 },
            ]);
        }
    }, [Bank]); // Re-run if Bank changes


    function extractEmployeeData(data) {
        return data.map((employee, index) => {
            return {
                _id: employee?._id,
                id: index,
                salary_mode: employee.employee.salary_mode,
                machine_code: employee.employee.machine_code,
                bank: employee?.employee?.bank?.discription,
                bankNo: employee?.employee?.bank_ac_no,
                bankBranch: employee?.employee?.bankBranch,
                ifsc_code: employee?.employee?.ifsc_code,
                Month,
                bankHold: employee?.employee?.bank_acc_holder,
                full_name: employee.employee.full_name,
                department_description: employee?.employee?.department?.discription || '',
                gross_salary: employee.gross_salary,
                total_attendance: employee.total_attendance,
                calculated_gross: employee.calculated_gross,
                amt: employee.amt,
                total_add: employee.total_add,
                less_advance: employee.less_advance,
                less_pf: employee.less_pf,
                less_loan: employee.less_loan,
                less_esic: employee.less_esic,
                total_less: employee.total_less,
                net_salary: employee.net_salary,
                ot_hrs: Number(employee.ot_hrs?.toFixed(2)),
                PT: employee?.salryhd_or_group.find(
                    item => item?.salaryhead_name?.salaryhead_name === "Professional Tax"
                )?.amount || 0,
            };
        });
    }

    const generateFileName = () => {
        const now = new Date();
        const date = now.toLocaleDateString()?.replace(/\//g, '-'); // Format date as YYYY-MM-DD
        const time = now.toLocaleTimeString()?.replace(/:/g, '-');  // Format time as HH-MM-SS

        const prefix = 'BANK SALARY SHEET';
        return `${prefix}_${Month}_${date}_${time}`;

        // return `AbsentReport_${date}_${time}`;
    };

    const formatjsDate = (date) => {
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        let year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const printrow = (data) => {
        const rowHead = columns.map(col => `<th style="padding: 10px; background-color: #f2f2f2; color: black;">${col.headerName}</th>`).join('');

        const rowsHTML = data?.map((row, index) => {
            const rowStyle = index % 2 === 0 ? 'background-color: #ffffff;' : 'background-color: #f9f9f9;';
            return `
            <tr style="${rowStyle}">
                ${columns.map(col => `<td style="padding: 10px; border: 1px solid #ddd;">${row[col.field] || 'N/A'}</td>`).join('')}
            </tr>
            `;
        }).join('');

        const totalNetSalary = data.reduce((total, current) => Number(total) + Number(current?.net_salary), 0);

        let Text = Bank?.mainData;
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);

        Text = Text?.replace('$AccountNumber', Bank?.accountNo)
            .replace('$Cheque', NewCheque)
            .replace('$Total', totalNetSalary)
            .replace(/\n/g, '<br />');

        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Authorization Letter</title>
            <style>
                body {
                    font-family: 'Arial', sans-serif;
                    margin: 10px;
                    line-height: 1.6;
                    background-color: #f5f5f5;
                }
                .content {
                    max-width: 800px;
                    margin: 0 auto;
                    padding: 10px;
                    border: 1px solid #000;
                    background-color: #ffffff;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                }
                .date, .footer {
                    margin-bottom: 20px;
                    font-size: 16px;
                }
                .footer {
                    margin-top: 40px;
                    font-weight: bold;
                }
                .signature {
                    margin-top: 60px;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-top: 20px;
                }
                th, td {
                    padding: 10px;
                    border: 1px solid #ddd;
                    text-align: left;
                }
                th {
                    background-color: #007bff;
                    color: white;
                }
                tr:hover {
                    background-color: #f1f1f1;
                }
                .hr-text {
                    position: absolute;
                    below: 100px;
                    right: 40px;
                    font-size: 12px;
                    font-weight: bold;
                    color: #333;
                }
            </style>
        </head>
        <body>
            <div class="content">
                <div class="date">
                    Date: ${formatjsDate(new Date())}
                </div>
                <div>
                    ${Bank?.header?.replace(/\n/g, '<br />')}
                </div>
                <div style="margin-top: 20px;">
                    <strong>Subject:</strong> ${Bank?.subject?.replace(/\n/g, '<br />')}
                </div>
                <div style="margin-top: 20px;">
                ${Text}
                </div>
                <table>
                    <thead>
                        <tr>${rowHead}</tr>
                    </thead>
                    <tbody>
                        ${rowsHTML}
                    </tbody>
                </table>
                <div class="signature">
                    ${DivValues?.div_mast}
                </div>
                <div class="footer">
                    AUTHORIZED SIGNATORY
                    <span class="hr-text">Print By ${userSchema?.usrnm || ''}</span>
                </div>
            </div>
        </body>
        </html>
        `);
        printWindow.document.close();
        handleCloseModal();
    };

    const Division_masterGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user_rights/Division_masterGET`,
                {
                    params: {
                        masterid: userSchema?.masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                        getDiv: JSON.parse(divIdString)
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDivValues(response?.data?.lastEntryNo[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        Division_masterGET();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ padding: '10px', }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                                Bank Salary List
                            </Typography>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>

                                <FormControl sx={{ m: 1, width: 120 }} size="small">

                                    <Select
                                        value={Month}
                                        onChange={(e) => setMonth(e.target.value)}
                                        sx={{ borderRadius: '14px' }}
                                    >
                                        {months.map(month => (
                                            <MenuItem key={month} value={month}>{month}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ m: 1, width: 160, }} size="small">
                                    <Autocomplete
                                        value={Bank}
                                        onChange={(event, newValue) => {
                                            setBank(newValue);
                                        }}
                                        options={BankNames}
                                        getOptionLabel={(option) => option.discription}
                                        getOptionSelected={(option, value) => option._id === value}
                                        // style={{ height: '38px', fontSize: '14px' }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Bank"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    'aria-label': 'Select Employee',
                                                    style: { paddingTop: '0px', },
                                                }}
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        borderRadius: '14px', // Applies border-radius to the input field
                                                    },
                                                }} />
                                        )}
                                        fullWidth
                                    />
                                </FormControl>

                                <Button
                                    variant="contained"
                                    onClick={() => { setOpenModal(true) }}
                                    color="primary"
                                    disabled={data.length === 0 || !Bank}
                                    sx={{ borderRadius: '14px', padding: '8px 16px' }}
                                >
                                    Print
                                </Button>
                            </div>
                        </div>
                        {/* <MUIDataTable
                            title={null}
                            data={data}
                            columns={columns}
                            options={options}
                        /> */}

                        <DataGrid
                            rows={data}
                            columns={columns}
                            initialState={{
                                ...data.initialState,
                                pagination: { paginationModel: { pageSize: 15 } },
                            }}
                            pageSizeOptions={[15, 25, 100]}
                            pagination
                            autoHeight
                            checkboxSelection={false}
                            disableSelectionOnClick
                            density="compact"
                            loading={loading}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    csvOptions: { fileName: generateFileName() },
                                },
                            }}
                        />
                    </Paper>
                </Grid>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                            Add Cheque Number
                        </Typography>
                        <TextField
                            label="Cheque Number"
                            variant="outlined"
                            fullWidth
                            size='small'
                            value={NewCheque}
                            onChange={(e) => setNewCheque(e.target.value)}
                            sx={{ mt: 2 }}
                        />
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                                Cancel
                            </Button>
                            <Button onClick={()=>{printrow(data)}} variant="contained" color="error" disabled = {!NewCheque}>
                                Print
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Grid>
        </>
    );
}

export default BankSalaryList;
