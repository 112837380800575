import React, { useState, useEffect } from 'react';
import { Modal, Typography, Grid, Box, Button, Paper, TextField, Divider, CircularProgress, Select, MenuItem, Container } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useRef, useLayoutEffect } from 'react';

import moment from 'moment';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-columnsContainer': {
            height: '40px', // Adjust the height of the column header
        },
        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
            width: '30px', // Adjust the width of columns and cells
        },
        '& .MuiDataGrid-viewport': {
            minHeight: '100px', // Adjust the height of the grid viewport
        },
    },
});

const MachineWiseReq = () => {
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [tempStartDate, setTempStartDate] = useState(new Date());
  const [tempEndDate, setTempEndDate] = useState(new Date());
  const dateRangePickerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (ranges) => {
    // Update the tempStartDate and tempEndDate independently
    const { startDate, endDate } = ranges.selection;
    if (startDate && endDate) {
      if (startDate <= tempEndDate) {
        setTempStartDate(startDate);
      }
      if (endDate >= tempStartDate) {
        setTempEndDate(endDate);
      }
    }
  };

  const applyDateRange = () => {
    setSelectionRange({
      startDate: tempStartDate,
      endDate: tempEndDate,
      key: 'selection',
    });
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
      setIsOpen(false); // Close the date picker when clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDateRangePicker = () => {
    setIsOpen(!isOpen);
  };

  const formatDates = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


    const [searchText, setSearchText] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage to 0
    const [totalCount, setTotalCount] = useState(0); // State to hold total count of rows
    const [sumdata, setSumData] = useState([]);
    const [CatVal, setCatVal] = useState([]);
    const [startDate, setstartDate] = React.useState(dayjs());
    const [endDate, setendDate] = React.useState(dayjs());
    const [SelectedDay, setSelectedDay] = useState(3);

    const Days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
    ];

    const classes = useStyles();

    const fetchSummaryData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token');
            console.log(startDate)
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/GetAbsentReport`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    // startDate,
                    // endDate,
                    SelectedDay,
                    startDate: selectionRange.startDate,
                    endDate: selectionRange.endDate,
                },
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            setSumData(response.data.summary);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchSummaryData()
        // }, [startDate, endDate, SelectedDay])
    }, [selectionRange, SelectedDay])


    const CustomToolbar = () => {

        return (
            <Grid container spacing={1} alignItems="center" justifyContent="flex-end" style={{ width: '90%' }}>

                <Grid item xs={1} sx={{ marginBlockStart: '-50px' }}>
                </Grid>

                <Grid item xs={3} sx={{ marginBlockStart: '-50px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={startDate}
                            label='Start Date'
                            onChange={(newValue) => setstartDate(newValue)}
                            fullWidth
                            inputFormat="yyyy-dd-MM"
                            sx={{ height: '38px' }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3} sx={{ marginBlockStart: '-50px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={endDate}
                            label='End Date'
                            onChange={(newValue) => setendDate(newValue)}
                            fullWidth
                            inputFormat="yyyy-dd-MM"
                            sx={{ height: '38px' }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2} sx={{ marginBlockStart: '-50px' }}>
                </Grid>
            </Grid>
        );
    };

    const options = {
        pagination: true,
        download: true,
        // customToolbar: () => <CustomToolbar />,
        downloadOptions: {
            filename: 'data',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            },
        },
        filter: false,
        page: currentPage,
        count: totalCount, // Set the count to totalCount
        selectableRows: false,
        setRowProps: (row) => ({
            style: { padding: '0px' }
        })
    };

    // const columns = [
    //     {
    //         name: "empname", label: "Employee", options: {
    //             setCellProps: () => ({ style: { width: "25%" } }),

    //             // customBodyRender: (data, type, row) => {return <pre>{data}</pre>}
    //         },
    //     },
    //     {
    //         name: "machine_code", label: "Machine Code", options: {
    //             setCellProps: () => ({ style: { width: "25%", } }),
    //             // setCellHeaderProps: () => ({ style: { width: "25%", textAlign:'center'}})
    //         },
    //     },
    //     {
    //         name: "deptname", label: "Department", options: {
    //             setCellProps: () => ({ style: { width: "25%" } }),
    //         },
    //     },
    //     // { name: "Shift", label: "Shift" },
    //     {
    //         name: "count", label: "Days", options: {
    //             setCellProps: () => ({ style: { width: "25%" } }),
    //         },
    //     },
    // ];

    const columns = [
        { field: "empname", headerName: "Employee", flex: 1, },
        { field: "machine_code", headerName: "Employee ID", flex: 1 },
        { field: "deptname", headerName: "Department", flex: 1 },
        { field: "count", headerName: "Absent", flex: 1 },
        { field: "ulCount", headerName: "Unpaid Leave", flex: 1 },
        { field: "total", headerName: "Total", flex: 1 },
    ];

    const Newsumdata = sumdata.map((item, index) => ({
        ...item,
        id: index,
    }));

    const generateFileName = () => {
        const now = new Date();
        const date = now.toLocaleDateString().replace(/\//g, '-'); // Format date as YYYY-MM-DD
        const time = now.toLocaleTimeString().replace(/:/g, '-');  // Format time as HH-MM-SS
        
        return `ABSENT REPORT_${date}_${time}`;
      };

    return (
        <>
            <Container maxWidth="xl" style={{ minHeight: '400px' }}>

                {/* <Box sx={{ border: '1px solid black', padding: '20px', marginBottom: '20px', width: '100%' }}> */}
                {/* <div style={{ backgroundColor: '#007bff', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
                {/* <Typography variant="h6" style={{ color: 'white' }}>
                            Absent Report
                        </Typography> */}
                {/* </div> */}


                {/* <MUIDataTable title={<span style={{ fontWeight: 'bold', fontSize: '23px' }}>Absent Report</span>} data={sumdata} columns={columns} options={options} /> */}

                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                    <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>
                        Absent Report
                    </Typography>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ width: '150px' }}>
                                <DatePicker
                                    value={startDate}
                                    onChange={(newValue) => setstartDate(newValue)}
                                    slotProps={{ textField: { size: 'small' } }}
                                    renderInput={(params) => <TextField {...params} />}
                                    label='Start Date'
                                    inputFormat="dd-MM-yyyy"
                                    format="DD/MM/YYYY" // Set the desired date format here
                                />
                            </Box>
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ width: '150px' }}>
                                <DatePicker
                                    value={endDate}
                                    onChange={(newValue) => setendDate(newValue)}
                                    label='End Date'
                                    inputFormat="dd-MM-yyyy"
                                    format="DD/MM/YYYY" // Set the desired date format here
                                    slotProps={{ textField: { size: 'small' } }}
                                    renderInput={(params) => <TextField {...params} />} />
                            </Box>
                        </LocalizationProvider> */}
                        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative',}}>
                                <TextField
                                    type="text"
                                    size="small"
                                    label="Select Range"
                                    value={`${formatDates(selectionRange.startDate)} - ${formatDates(selectionRange.endDate)}`}
                                    onFocus={toggleDateRangePicker}
                                    readOnly
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true, // Remove default underline
                                        style: { cursor: 'pointer', textAlign: 'center' } // Center-align text and change cursor to pointer when hovering
                                    }}
                                    InputLabelProps={{
                                        shrink: true, // Ensure the label doesn't float above the input when value is present
                                    }}
                                    onClick={toggleDateRangePicker} // Open DateRangePicker on click
                                />
                                {isOpen && (
                                    <div style={{ position: 'absolute', zIndex: 999, marginRight: '400px' }} ref={dateRangePickerRef}>
                                        <DateRangePicker
                                            ranges={[{ startDate: tempStartDate, endDate: tempEndDate, key: 'selection' }]}
                                            onChange={handleSelect}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={applyDateRange}
                                            fullWidth
                                            style={{ marginTop: '10px' }}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                )}

                            </div>
                        <Box sx={{ width: '150px' }}>
                            <Autocomplete
                                value={SelectedDay}
                                onChange={(e, newValue) => setSelectedDay(newValue)}
                                options={Days}
                                disableClearable
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Days"
                                        inputProps={{ ...params.inputProps, 'aria-label': 'Select Day' }}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </Box>
                    </div>
                </div>

                <DataGrid
                    rows={Newsumdata}
                    columns={columns}
                    initialState={{
                        ...Newsumdata.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },

                    }}
                    pageSizeOptions={[10, 25, 100]}
                    pagination
                    autoHeight
                    checkboxSelection={false}
                    disableSelectionOnClick
                    density="compact"
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { fileName: generateFileName() }, 
                        },
                    }}
                />
                {/* </Box> */}
            </Container>
        </>
    );
};

export default MachineWiseReq;
