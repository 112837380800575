import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useRef, useLayoutEffect } from 'react';
import { CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';

import axios from 'axios';
import { Select, MenuItem, Grid, TextField, Paper } from '@mui/material';


function TransactionAdvance() {
    const [loading, setLoading] = useState(true);
    const [maindata, setmainData] = useState([]);
    const [uncontrolledValue, setUncontrolledValue] = React.useState(dayjs());
    const [controlledValue, setControlledValue] = React.useState(dayjs());
    const [Month, setMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
    const [error, setError] = useState('');
    const [AdvanceList, setAdvanceList] = useState([]);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [DeleteIndexArray, setDeleteIndexArray] = useState([]); // State to store the index of item to be deleted
    const [openDeleteAllModal, setopenDeleteAllModal] = useState(false); // State to control the modal visibility

    const [department, setDepartment] = useState('');
    const [DepValues, setDepValues] = useState([]);

    const [GivenAdvanceList, setGivenAdvanceList] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const [openEditModal, setOpenEditModal] = useState(false); // State to control modal visibility
    const [editedData, setEditedData] = useState(null); // State to hold edited data
    const [selectedRows, setSelectedRows] = useState([]);

    const [deleteIndex, setDeleteIndex] = useState(null);

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '75px';
        }
    }, []);

    // Function to handle changes in row selection
    const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
        // Extract the data of the selected rows
        const selectedData = allRowsSelected.map(rowIndex => AdvanceList[rowIndex.dataIndex]);
        // Update the selected rows state
        setSelectedRows(selectedData);
    };

    // Function to handle opening the modal
    const handleOpenEditModal = () => {
        setOpenEditModal(true);
    };

    // Function to handle closing the modal
    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    };

    // Function to handle saving the edited data
    const handleSaveData = () => {
        // Update the AdvanceList based on the edited data
        const updatedAdvanceList = AdvanceList.map(item => {
            if (item.employee_id === editedData.employee_id) {
                return { ...item, advance_remarks: editedData.advance_remarks, advance_amt: editedData.advance_amt };
            }
            return item;
        });

        // Set the updated AdvanceList
        setAdvanceList(updatedAdvanceList);
        console.log("----", selectedRows, '-----')
        // Update the selected rows state
        const updatedSelectedRows = selectedRows.map(row => {
            // Find the corresponding row in the updated AdvanceList
            const updatedRow = updatedAdvanceList.find(item => item.employee_id === row.employee_id);
            return updatedRow || row; // Keep the selected row if found, otherwise use the original row
        });

        setSelectedRows(updatedSelectedRows);

        // Close the modal
        handleCloseEditModal();
    };



    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const DepTypeGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            user: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/DepartmentTypeGET`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDepValues(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        DepTypeGET();
    }, []);

    function addTotalCount(data) {
        if (!Array.isArray(data)) {
            console.error("Data is not an array:", data);
            return []; // Return an empty array if data is not valid
        }
        // console.log("--===", data, "==-----")
        data.forEach(entry => {
            let totalCount = 0;
            let OTCount = 0;

            entry?.summary?.forEach(item => {
                const lvname = item?._id.lvname[0];
                const day_count = item?._id?.day_count ? Number(item?._id?.day_count[0]) || 1 : 1;
                const benifit_leave = item?._id?.benifit_leave[0];
                // if (lvname === 'P' || lvname === 'WO' || lvname === 'H') {
                if (benifit_leave === 'Y' || benifit_leave === 'Holiday') {
                    totalCount += item.count * day_count;
                }
                OTCount += (item?.otamount || 0)
            });
            entry.totalCount = totalCount; // Add totalCount property to each object
            entry.OTCount = OTCount; // Add totalCount property to each object
        });

        const result = data.map(entry => {
            const { _id, employee_name, advance_amt, summary = [], OTCount, totalCount, advance_remarks, radio_typ } = entry;

            // Safely extracting the first gross_sal from the summary array
            const gross_sal = parseFloat(summary[0]?.gross_sal?.[0] || 0);

            // Safely extracting the first deptname from the summary array
            const deptname = summary[0]?._id?.deptname?.[0] || 'N/A';

            // Calculating payable salary based on gross_sal and totalCount
            const salary = ((gross_sal / Number(summary[0]?.days?.[0] || 26)) * (totalCount || 0)).toFixed(2);

            // Calculating 80% of the salary
            const payable_salary = ((Number(salary) ) * 0.8).toFixed(2); //+ Number(OTCount)

            // Safely extracting the first machine_code from the summary array
            const machine_code = summary[0]?.machine_code?.[0] || 'N/A';

            return {
                _id: entry?._id,
                employee_id: employee_name?._id,
                deptname,
                full_name: employee_name?.full_name || employee_name?.empname || 'Unknown',
                advance_amt: advance_amt || 0,
                gross_sal,
                salary,
                payable_salary: payable_salary,
                OTCount: OTCount.toFixed(2),
                machine_code,
                totalCount: totalCount || 0,
                advance_remarks,
                radio_typ,
                id: employee_name?._id,
            };
        });


        return result;
    }

    function mergeSummaryIntoAdv(summary, summaryadv) {
        summary.forEach(summaryItem => {
            const empId = summaryItem?._id.emp_id[0];
            const empname = summaryItem?._id.empname[0]

            const index = summaryadv.findIndex(advItem => advItem?.employee_name?._id === empId);
            if (index !== -1) {
                if (!summaryadv[index].summary) {
                    summaryadv[index].summary = [];
                }
                summaryadv[index].summary.push(summaryItem);
            } else {
                summaryadv.push({
                    employee_name: {
                        _id: empId,
                        empname: empname,
                    },
                    summary: [summaryItem]
                });
            }
        });
        const newData = addTotalCount(summaryadv || []);
        return newData;
    }

    const roundupToNearest500 = (num) => {
        const remainder = num % 500;
        if (remainder <= 249) {
            return Math.floor(num / 500) * 500;
        } else {
            return Math.ceil(num / 500) * 500;
        }
    };

    const fetchAdvanceData = async () => {
        try {
            setLoading(true)
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/advance/GetSalary`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    Month,
                    endDate: controlledValue,
                    startDate: uncontrolledValue,
                    department: department?._id
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            const datatoupdate = await mergeSummaryIntoAdv(response.data.summary, response.data.summaryadv);

            const NewAdvanceDATAS = (data) => {
                return data.map(employee => {
                    const updatedAdvanceAmt = roundupToNearest500(employee.payable_salary);
                    return { ...employee, advance_amt: updatedAdvanceAmt };
                });
            };

            setAdvanceList(NewAdvanceDATAS(datatoupdate));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchAdvanceData()
        GivenAdvanceListGET();
    }, [Month, controlledValue, uncontrolledValue, department])

    const options = {
        pagination: true,
        download: true,
        viewColumns: false,
        print: false,
        filter: true,
        filterType: 'textField',
        customToolbar: () => <CustomToolbar />,
        selectableRows: 'multiple',
        onRowSelectionChange: handleRowSelectionChange,
        customToolbarSelect: () => null, // Remove the default delete icon
        hideToolbarSelect: true,
        textLabels: {
            body: {
                noMatch: loading ?
                    <CircularProgress /> :
                    'Sorry, there is no matching data to display',
            },
        }
    };

    const UpdateAdvance = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/advance/UpdateAdvance`,
                {
                    advance_arry: selectedRows,
                    Month: Month,
                    advance_date: controlledValue,
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            if (response.data.success) {
                setActionMessage(response.data.message)
                setSuccessModalOpen(true)
                fetchAdvanceData();
                GivenAdvanceListGET();
                setSelectedRows([]);
            }
            else {
                setActionMessage(response.data.message)
                setErrorModalOpen(true)
                await fetchAdvanceData();
                await GivenAdvanceListGET();
                setSelectedRows([]);
            }

        } catch (error) {
            setActionMessage('Some error occured.')
            setErrorModalOpen(true)
            setSelectedRows([]);
            console.error('Error fetching data:', error);
        }
    }
    const CustomToolbar = () => {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return (
            <Grid container spacing={1} alignItems="center" justifyContent="flex-end" style={{ width: '90%' }}>

                <Grid item xs={2} sx={{ marginBlockStart: '-40px' }}>
                    <Autocomplete
                        value={department}
                        onChange={(event, newValue) => {
                            setDepartment(newValue);
                        }}
                        options={DepValues}
                        getOptionLabel={(option) => option?.discription || option?.description || ""}
                        getOptionSelected={(option, value) => option._id === value}
                        // style={{ height: '38px', fontSize: '14px' }}
                        style={{ height: '35px', fontSize: '14px' }}
                        renderInput={(params) => <TextField {...params} variant="outlined" size='small' label='Department'

                            inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                            sx={{
                                "& .MuiInputBase-root": { height: "35px" },
                            }}
                        />}
                    />
                </Grid>
                <Grid item xs={2} sx={{ marginBlockStart: '-50px', }}>
                    <Select
                        value={Month}
                        onChange={(e) => setMonth(e.target.value)}
                        fullWidth
                        sx={{
                            textAlign: 'center',
                            width: '100%', // Reduce width to make it smaller
                            height: '35px', // Adjust height
                            // marginRight: '10px',
                            backgroundColor: 'white',
                            marginTop: '10px',
                        }}                    >
                        {months.map(month => (
                            <MenuItem key={month} value={month}>{month}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={3} sx={{ marginBlockStart: '-50px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={uncontrolledValue}
                            onChange={(newValue) => setUncontrolledValue(newValue)}
                            fullWidth
                            label='End Date'
                            inputFormat="dd-MM-yyyy"
                            format="DD/MM/YYYY" // Set the desired date format here
                            sx={{ height: '30px', '& input': { height: '5px' }, }} // Adjust height of both input and picker
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3} sx={{ marginBlockStart: '-50px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={controlledValue}
                            onChange={(newValue) => setControlledValue(newValue)}
                            fullWidth
                            label='Kharchi Date'
                            inputFormat="dd-MM-yyyy"
                            format="DD/MM/YYYY" // Set the desired date format here
                            sx={{ height: '30px', '& input': { height: '5px' }, }} // Adjust height of both input and picker
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2} sx={{ marginBlockStart: '-50px' }}>
                </Grid>
            </Grid>
        );
    };

    const columns = [
        { name: "machine_code", label: "Employee Code", },
        { name: "full_name", label: "Employee Name" },
        { name: "deptname", label: "Department" },
        { name: "totalCount", label: "Present Day" },
        { name: "gross_sal", label: "Gross Salary" },
        { name: "salary", label: "Salary" },
        { name: "payable_salary", label: "Payable Salary" },
        { name: "OTCount", label: "OT Count" },
        { name: "advance_amt", label: 'Advance Amount' },
        { name: "advance_remarks", label: 'Remarks' },
        {
            name: "edit",
            label: "Edit",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Button variant="text" color="primary" size='small'
                            onClick={() => handleEdit(AdvanceList[dataIndex])}><EditIcon fontSize="small" /></Button>
                    );
                }
            }
        },
        {
            name: "_id",
            label: 'Paid',
            options: {
                customBodyRender: (value) => {
                    return value ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />;
                }
            }
        },
    ];
    const convertToIndianTime = (utcDate) => {
        const date = new Date(utcDate);
        // Add 5 hours and 30 minutes to the UTC date
        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);
        // Format the date as required
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const indianTime = `${day}-${month}-${year}`;
        return indianTime;
    };

    const NewGivenAdvanceList = GivenAdvanceList.map((item, index) => {
        return {
            ...item,
            id: index + 1,
            advance_date: convertToIndianTime(item?.advance_date),
        };
    });

    const GivennAdvanceColumns = [
        { field: "empname", headerName: "Employee", flex: 1 },
        { field: "department", headerName: "Department", flex: 1 },
        { field: "salary", headerName: "Salary", flex: 1 },
        { field: "advance_amt", headerName: "Advance Amount", flex: 1 },
        { field: "advance_date", headerName: "Advance Date", flex: 1 },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => (
                <Button variant="text" color="error" size='small' onClick={() => handleDelete(params.row)}><DeleteIcon fontSize="small" /></Button>
            )
        },
    ];

    const handleEdit = (editdata) => {
        console.log(AdvanceList);
        setEditedData(editdata);
        handleOpenEditModal();
    };

    const GivenAdvanceListGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/advance/GivenAdvanceListGET`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    month: Month,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setGivenAdvanceList(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleDelete = (INFO) => {
        setDeleteIndex(INFO._id);
        setOpenModal(true);
    };

    const handleModalDelete = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/advance/AdvanceDELETE/${deleteIndex}`, {}, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            handleCloseModal();
            GivenAdvanceListGET();
            fetchAdvanceData();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleDeleteAll = () => {
        // Map selected row IDs to an array
        console.log(NewGivenAdvanceList);
        const ids = NewGivenAdvanceList.map(item => item?._id);
        // console.log(ids)
        setDeleteIndexArray(ids);
        setopenDeleteAllModal(true);
    };

    const handleDeleteAllCloseModal = () => {

        setopenDeleteAllModal(false);
        setDeleteIndexArray([]);
    };

    const handleModalDeleteAll = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/advance/AdvanceDELETEAll`, { DeleteIndexArray }, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log('Delete API Response:', response.data);
            handleDeleteAllCloseModal();

            await GivenAdvanceListGET();
            setDeleteIndexArray([]);
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleFileChange = (event) => {
        try {
            const file = event.target.files[0];
            console.log('Selected file:', file);
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            // Get JWT token from localStorage
            const token = localStorage.getItem('user-token');
            // Create a FormData object
            const formData = new FormData();
            formData.append('advance_import', event.target.files[0]); // Append the file to FormData with key 'file'
            formData.append('user', userSchema?.usrnm);
            formData.append('compid', JSON.parse(companyIdString));
            formData.append('divid', JSON.parse(divIdString));
            formData.append('masterid', userSchema?.masterid);
            formData.append('Month', Month);
            formData.append('advance_date', controlledValue);

            // Make a POST request to your API endpoint
            axios.post(`${process.env.REACT_APP_API_URL}/advance/ImportFile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the Content-Type header to multipart/form-data
                    authorization: `Bearer ${token}`
                },
            })
                .then((response) => {
                    // Handle success response
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        setSuccessModalOpen(true);
                    } else {
                        setActionMessage(response.data.message)
                        // handleCancel();
                        setErrorModalOpen(true)

                    }
                })
                .catch((error) => {
                    setActionMessage(error.message)
                    setErrorModalOpen(true)
                    console.error('Error uploading file:', error);
                });
        } catch (error) {
            setActionMessage(error.message)
            setErrorModalOpen(true)
            console.error('Error uploading file:', error);
        }
        finally {
            event.target.value = null; // Reset file input
        }
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };

    const generateFileName = () => {
        const now = new Date();
        const date = now.toLocaleDateString().replace(/\//g, '-'); // Format date as YYYY-MM-DD
        const time = now.toLocaleTimeString().replace(/:/g, '-');  // Format time as HH-MM-SS

        const prefix = 'ADVANCE SHEET';
        return `${prefix}_${Month}_${date}_${time}`;
    };

    return (
        <>
            <Container maxWidth="xl" style={{ marginTop: '25px', minHeight: '400px' }} ref={lastContentRef}>
                {/* <Box sx={{ border: '1px solid black', padding: '20px', marginBottom: '20px' }}> */}
                {/* <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                        Advance
                    </Typography> */}
                {/* <Typography variant="h6" sx={{ color: 'white', margin: '0', backgroundColor: '#007bff', padding: '10px' }}>
                        Employee Advance List
                    </Typography> */}
                <MUIDataTable title={<span style={{ fontWeight: 'bold', fontSize: '23px' }}>Advance</span>} data={AdvanceList} columns={columns} options={options} />
                {/* <MUIDataTable title={<span style={{ fontWeight: 'bold', fontSize: '23px' }}>Advance List</span>} data={NewGivenAdvanceList} columns={GivennAdvanceColumns} options={GivennAdvanceOptions} /> */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>
                        Advance List
                    </Typography>
                    <Button variant='contained' color='error' size='small' onClick={() => { handleDeleteAll() }} sx={{
                        marginRight: '10px',
                        borderRadius: '14px',
                        borderColor: 'red',
                    }}
                    >Delete Advance</Button>
                </div>
                <DataGrid
                    rows={NewGivenAdvanceList}
                    columns={GivennAdvanceColumns}
                    initialState={{
                        ...NewGivenAdvanceList.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },

                    }}
                    pageSizeOptions={[10, 25, 100]}
                    pagination
                    autoHeight
                    checkboxSelection={false}
                    disableSelectionOnClick
                    density="compact"
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { fileName: generateFileName() },
                        },
                    }}
                />
                {/* <DataGrid
                    rows={AdvanceList}
                    columns={column}
                    checkboxSelection
                    onRowSelectionModelChange ={handleRowSelectionChangessss}
                    disableSelectionOnClick
                    autoHeight
                    editMode="row"
                    onCellEditCommit={handleCellEditCommit}
                /> */}
                {/* </Box> */}
                <Modal
                    open={successModalOpen}
                    onClose={handleSuccessModalClose}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                        <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                        <p id="success-modal-description">{ActionMessage}</p>
                        <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                    </div>
                </Modal>

                <Modal
                    open={errorModalOpen}
                    onClose={handleErrorModalClose}
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                        <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                        <p id="error-modal-description">{ActionMessage}.</p>
                        <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                    </div>
                </Modal>

                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                            Confirm Deletion
                        </Typography>
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to delete this item?
                        </Typography>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                                Cancel
                            </Button>
                            <Button onClick={handleModalDelete} variant="contained" color="error">
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                <Modal
                    open={openDeleteAllModal}
                    onClose={handleDeleteAllCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                            Confirm Deletion
                        </Typography>
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to delete all advance?
                        </Typography>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleDeleteAllCloseModal} color="primary" sx={{ mr: 2 }}>
                                Cancel
                            </Button>
                            <Button onClick={handleModalDeleteAll} variant="contained" color="error">
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                <Dialog open={openEditModal} onClose={handleCloseEditModal}>
                    <DialogTitle>Edit Data</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="advance_remarks"
                            label="Remarks"
                            type="text"
                            fullWidth
                            value={editedData?.advance_remarks}
                            onChange={(e) => setEditedData({ ...editedData, advance_remarks: e.target.value })}
                        />

                        <TextField
                            margin="dense"
                            id="advance_amt"
                            label="Advance Amount"
                            type="number"
                            fullWidth
                            value={editedData?.advance_amt}
                            onChange={(e) => setEditedData({ ...editedData, advance_amt: e.target.value })}
                        />

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleCloseEditModal}>Cancel</Button>
                        <Button onClick={handleSaveData} color="primary">Save</Button>
                    </DialogActions>
                </Dialog>
                <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <Button style={{ marginRight: '8px' }} variant="contained" color="error" >
                            Cancel
                        </Button>
                        <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={UpdateAdvance} disabled={selectedRows.length === 0} >
                            Save
                        </Button>

                        <input
                            accept=".xlsx, .xls"
                            id="import-profile"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <label htmlFor="import-profile">
                            <Tooltip title="Import Employee Master" arrow>
                                <Button
                                    variant="contained"
                                    component="span"
                                    sx={{
                                        marginLeft: '16px',
                                        // borderRadius: '14px',
                                        borderColor: 'red',
                                    }}
                                >
                                    Import Advance
                                </Button>
                            </Tooltip>
                        </label>
                    </div>
                </Paper>
            </Container>


        </>
    );
}

export default TransactionAdvance;
